import type { VariantProp } from '../../common/colors';
import type { SpacingScale } from '../../utilities/shared/sizes';
import type { IconName } from '../Icon/Icon';
import { colors, styled } from '../../stitches.config';
import { AssetTile } from '../AssetTile/AssetTile';
import { Icon } from '../Icon/Icon';

const FramedIconLiteral = styled(Icon, {
  display: 'flex',
  width: '100%',
  height: '100%',
  color: colors.iconNeutralLight,

  variants: {
    variant: {
      alternative: { color: colors.iconAlternativeLight },
      attention: { color: colors.iconAttentionLight },
      brand: { color: colors.iconBrandLight },
      neutral: { color: colors.iconNeutralLight },
      negative: { color: colors.iconNegativeLight },
      positive: { color: colors.iconPositiveLight },
    },
    internal: {
      true: { color: colors.internalIconLight },
      false: {},
    },
  },
});

export interface FramedIconProps {
  /**
   * Set which icon to display.
   */
  icon: IconName;
  /**
   * Set whether the icon is internal.
   */
  internal?: boolean;
  /**
   * Set a size of the component.
   */
  size?: SpacingScale;
  /**
   * Set a variant of the component.
   */
  variant?: VariantProp;
}

export function FramedIcon({ icon, internal, size = 28, variant, ...remaining }: FramedIconProps) {
  return (
    <AssetTile size={size} {...remaining}>
      <FramedIconLiteral icon={icon} internal={internal} size={size} variant={variant} />
    </AssetTile>
  );
}
