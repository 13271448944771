import { LogoSVG } from 'atto-svgs';

import type { VariantProp } from '../../common/colors';
import type { SpacingScale } from '../../utilities/shared/sizes';
import { getVariantStyles, variantIconColor } from '../../common/variant';
import { styled } from '../../stitches.config';

export const LogoIcons = {
  initial: LogoSVG.Initial,
  word: LogoSVG.Word,
};

export type LogoIconName = keyof typeof LogoIcons;
export const LogoIconNames = Object.keys(LogoIcons) as LogoIconName[];

export interface LogoProps {
  /**
   * Set if the logo is internal.
   */
  internal?: boolean;
  /**
   * Set a size of the component.
   */
  size?: SpacingScale;
  /**
   * Set which type of logo to display.
   */
  type: LogoIconName;
  /**
   * Set a variant of the component.
   */
  variant?: VariantProp;
}

const LogoLiteral = styled('svg', {
  display: 'flex',
  width: 'auto',
  color: variantIconColor,

  variants: {
    variant: {
      alternative: {
        ...getVariantStyles('alternative', false),
      },
      attention: {
        ...getVariantStyles('attention', false),
      },
      brand: {
        ...getVariantStyles('brand', false),
      },
      negative: {
        ...getVariantStyles('negative', false),
      },
      neutral: {
        ...getVariantStyles('neutral', false),
      },
      positive: {
        ...getVariantStyles('positive', false),
      },
    },
    internal: {
      true: {
        ...getVariantStyles('neutral', true),
      },
      false: {},
    },
  },
});

export function Logo({
  internal,
  size = 28,
  type = 'word',
  variant = 'brand',
  ...remaining
}: LogoProps) {
  const NamedIcon = LogoIcons[type];

  if (!NamedIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${type}`);
    return null;
  }

  return (
    <LogoLiteral
      as={NamedIcon}
      internal={internal}
      variant={variant}
      css={{ heightAll: `$${size}` }}
      {...remaining}
    />
  );
}
