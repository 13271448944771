import type { ToastProps, VariantProp } from '@meterup/atto';
import { ControlGroup, styled, TerminateButton, Toast, zIndexes } from '@meterup/atto';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback } from 'react';
import reactHotToast, { useToaster } from 'react-hot-toast';

export enum Durations {
  PositiveConfirmation = 3500,
  ErroneousCTA = 5000,
  Dismissable = 10000,
}

type NotificationActionProps = {
  toastId: string;
  actions?: React.ReactNode;
  dismissible?: boolean;
  variant: VariantProp;
};

function NotificationActions({ toastId, actions, dismissible, variant }: NotificationActionProps) {
  const dismiss = useCallback(() => reactHotToast.dismiss(toastId), [toastId]);
  return (
    <ControlGroup relation="separate">
      {actions && actions}
      {dismissible && (
        <TerminateButton onClick={dismiss} icon="cross" label="Dismiss" variant={variant} />
      )}
    </ControlGroup>
  );
}

type NotifyOptions = {
  duration?: number;
  dismissible?: boolean;
  actions?: NotificationActionProps['actions'];
};

export const notify = (
  heading: string,
  {
    actions,
    description,
    dismissible,
    duration,
    icon,
    state,
    variant = 'neutral',
  }: Omit<ToastProps, 'heading'> & NotifyOptions = {},
) => {
  let durationValue = Durations.PositiveConfirmation;
  if (state === 'running') {
    durationValue = Infinity;
  }
  if (variant === 'negative') {
    durationValue = Durations.ErroneousCTA;
  }
  if (dismissible) {
    durationValue = Durations.Dismissable;
  }

  reactHotToast(
    (toast: any) => (
      <Toast
        as={motion.div}
        key={toast.id}
        {...toast.ariaProps}
        variant={variant}
        state={state}
        icon={icon}
        heading={heading}
        description={description}
        actions={
          (actions || dismissible || state === 'running') && (
            <NotificationActions
              toastId={toast.id}
              dismissible={dismissible || state === 'running'}
              actions={actions}
              variant={variant}
            />
          )
        }
        layout
        initial={{ y: 40 }}
        animate={{ y: 0, scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0, transition: { duration: 0.2 } }}
        transition={{ type: 'spring', stiffness: 500, damping: 20 }}
      />
    ),
    {
      duration: duration ?? durationValue,
    },
  );
};

const NotificationContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  gap: '$8',
  width: 'fit-content',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: zIndexes.alert,

  variants: {
    hasToasts: {
      true: {
        paddingBottom: '$20',
      },
      false: {
        marginBottom: '$20',
      },
    },
  },
});

export function Notifications() {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;

  const hasVisibleToasts = toasts.length > 0;
  return (
    <NotificationContainer
      onMouseEnter={startPause}
      onMouseLeave={endPause}
      hasToasts={hasVisibleToasts}
    >
      <AnimatePresence>
        {toasts
          .filter((toast) => toast.visible)
          .map((toast) =>
            typeof toast.message === 'function' ? toast?.message(toast) : toast.message,
          )}
      </AnimatePresence>
    </NotificationContainer>
  );
}
