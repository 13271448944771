import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type { DisplayableError } from '@meterup/common';
import { ClientBadRequestError, ResourceNotFoundError, UnexpectedError } from '@meterup/common';
import { type GraphQLError, Kind } from 'graphql';
import { ClientError } from 'graphql-request';

/*
 * Known extension error codes that we support, will be defined from backend.
 */
export enum GraphQLExtensionErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  BadRequest = 'BAD_REQUEST',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  ValidationError = 'VALIDATION',
}

export { ClientError };

export function getGraphQLError(originalError: Error): GraphQLError | undefined {
  let error = originalError;
  if (!(error instanceof ClientError) && error?.cause != null && error.cause instanceof Error) {
    error = error.cause;
  }
  if (!(error instanceof ClientError)) return undefined;

  return error.response.errors?.[0];
}

export function getGraphQLErrorMessageOrEmpty(error: Error): string {
  const gqlError = getGraphQLError(error);
  return gqlError?.message ? `: ${gqlError.message}` : '';
}

export function getExtensionErrorCode(
  error: GraphQLError | undefined,
): GraphQLExtensionErrorCode | unknown {
  return error?.extensions?.code;
}

export function clientErrorToDisplayableError(clientError: ClientError): DisplayableError {
  const graphqlError = getGraphQLError(clientError);

  switch (graphqlError?.extensions?.code) {
    case GraphQLExtensionErrorCode.NotFound:
    case GraphQLExtensionErrorCode.Unauthorized:
      return new ResourceNotFoundError('Sorry, we were unable to find this resource.', clientError);
    case GraphQLExtensionErrorCode.AlreadyExists:
    case GraphQLExtensionErrorCode.BadRequest:
    case GraphQLExtensionErrorCode.ValidationError:
      return new ClientBadRequestError(graphqlError.message, clientError);
  }

  return new UnexpectedError(graphqlError?.message ?? clientError.message, clientError);
}

export function getDocumentName(document: TypedDocumentNode<any, any>): string | undefined {
  for (const def of document.definitions) {
    if (def.kind === Kind.OPERATION_DEFINITION) return def.name?.value;
  }

  return undefined;
}

export function getDocumentActions(document: TypedDocumentNode<any, any>): string[] | undefined {
  for (const def of document.definitions) {
    if (def.kind === Kind.OPERATION_DEFINITION) {
      return def.selectionSet.selections
        .filter(
          (selection): selection is typeof selection & { kind: Kind.FIELD } =>
            selection.kind === Kind.FIELD,
        )
        .map((selection) => selection.name.value);
    }
  }

  return undefined;
}
