import type { SpacingScale } from '../utilities/shared/sizes';

const size = {
  primary: 32,
  secondary: 20,
  tertiary: 16,
  quaternary: 12,
};

export const sizing = {
  primary: size.primary as SpacingScale,
  secondary: size.secondary as SpacingScale,
  tertiary: size.tertiary as SpacingScale,
  quaternary: size.quaternary as SpacingScale,

  /** Framing */
  ends: `$${size.secondary}`,
  endsOnly: `$${size.secondary} 0`,
  sides: `$${size.primary}`,
  sidesOnly: `0 $${size.primary}`,
  square: `$${size.primary}`,
  squish: `$${size.secondary} $${size.primary}`,
  gap: `$${size.tertiary}`,

  /** Content */
  contentEnds: `$${size.tertiary}`,
  contentEndsOnly: `$${size.tertiary} 0`,
  contentSides: `$${size.secondary}`,
  contentSidesOnly: `0 $${size.secondary}`,
  contentSquare: `$${size.secondary}`,
  contentSquish: `$${size.tertiary} $${size.secondary}`,
  contentGap: `$${size.quaternary}`,
};

export const SPACING_VALUE = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42,
  44, 46, 48, 52, 54, 56, 58, 60, 64, 68, 72, 76, 80, 84, 88, 92, 96, 100, 104, 108, 112, 116, 120,
  124, 128, 132, 136, 140, 144, 148, 152, 156, 160, 164, 168, 172, 176, 180, 184, 188, 192, 196,
  200, 204, 208, 212, 216, 220, 224, 228, 232, 236, 240, 244, 248, 252, 256, 260, 264, 268, 272,
  276, 280, 284, 288, 292, 296, 300, 304, 308, 312, 316, 320, 324, 328, 332, 336, 340, 344, 348,
  352, 356, 360, 364, 368, 372, 376, 380, 384, 388, 392, 396, 400, 404, 408, 412, 416, 420, 424,
  428, 432, 436, 440, 444, 448, 452, 456, 460, 464, 468, 472, 476, 480, 484, 488, 492, 496, 500,
  504, 508, 512, 516, 520, 524, 528, 532, 536, 540, 544, 548, 552, 556, 560, 564, 568, 572, 576,
  580, 584, 588, 592, 596, 600, 604, 608, 612, 616, 620, 624, 628, 632, 636, 640, 644, 648, 652,
  656, 660, 664, 668, 672, 676, 680, 684, 688, 692, 696, 700, 704, 708, 712, 716, 720, 724, 728,
  732, 736, 740, 744, 748, 752, 756, 760, 764, 768, 772, 776, 780, 784, 788, 792, 796, 800, 804,
  808, 812, 816, 820, 824, 828, 832, 836, 840, 844, 848, 852, 856, 860, 864, 868, 872, 876, 880,
  884, 888, 892, 896, 900, 904, 908, 912, 916, 920, 924, 928, 932, 936, 940, 944, 948, 952, 956,
  960, 964, 968, 972, 976, 980, 984, 988, 992, 996, 1000, 1004, 1008, 1012, 1016, 1020, 1024,
] as const;
export type SpacingValue = (typeof SPACING_VALUE)[number];
