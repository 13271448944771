import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { VariantProp } from '../../common/colors';
import type { PolymorphicComponentProps } from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { fade, palette } from '../../common/colors';
import { useControlSize } from '../../common/control_size';
import {
  getVariantStyles,
  useVariant,
  variantTokenBgColor,
  variantTokenIconColor,
  variantTokenLabelColor,
} from '../../common/variant';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { BodySansSizes } from '../../text/Body';
import { CaptionSansSizes } from '../../text/Caption';
import { SmallSansSizes } from '../../text/Small';
import { Text } from '../../text/Text';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import { useTableRowSelected } from '../Table/utils';
import { Tooltip } from '../Tooltip/Tooltip';
import { useBadgeEnds } from './BadgeContext';

export type BadgeArrangement = 'hidden-label' | 'leading-icon' | 'leading-label';
export type BadgeEnds = 'card' | 'pill';
export type BadgeSize = 'x-small' | 'small' | 'medium' | 'large';
export type BadgeVariant = VariantProp;

export type BadgeProps = {
  /**
   * Set the content's order and visibilty.
   */
  arrangement?: BadgeArrangement;
  /**
   * Button to use in place of Icon for visual users.
   */
  button?: React.ReactNode;
  /**
   * Displayed as the label of the component. **Required** for accessibility support.
   */
  children: React.ReactNode;
  /**
   * Boolean to disable the component.
   */
  disabled?: boolean;
  /**
   * Style choice for the left and right edges of the component.
   */
  ends?: BadgeEnds;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Boolean to show internal-only styles.
   */
  internal?: boolean;
  /**
   * Event handler for clicking on a badge.
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /**
   * Set a size of the component.
   */
  size?: BadgeSize;
  /**
   * Set the most appropriate variant of the component for your use.
   */
  variant?: VariantProp;
  /**
   * Set a width specifically for the badge.
   */
  width?: string;

  /**
   * Whether to elide the label if it overflows (truncate with ellipses).
   */
  elide?: boolean;

  ref?: React.Ref<HTMLDivElement | null>;
};

export const BadgeIcon = styled(Icon, {
  color: variantTokenIconColor,
  display: 'flex',

  variants: {
    size: {
      'x-small': {
        widthAll: '$12',
        heightAll: '$12',
      },
      small: {
        widthAll: '$12',
        heightAll: '$12',
      },
      medium: {
        widthAll: '$14',
        heightAll: '$14',
      },
      large: {
        widthAll: '$16',
        heightAll: '$16',
      },
    },
  },
});

const BadgeLabel = styled(Text, {
  maxWidth: '100%',
  color: variantTokenLabelColor,

  variants: {
    size: {
      'x-small': {
        ...CaptionSansSizes,
      },
      small: {
        ...SmallSansSizes,
      },
      medium: {
        ...BodySansSizes,
      },
      large: {
        ...BodySansSizes,
      },
    },
    elide: {
      false: {},
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
});

export const BadgeContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'fit-content',

  backgroundColor: variantTokenBgColor,

  variants: {
    elide: {
      false: {
        flexShrink: 0,
      },
      true: {
        flexShrink: 1,
        maxWidth: '100%',
      },
    },
    clickable: {
      true: {
        cursor: 'pointer',
        userSelect: 'none',
      },
      false: {},
    },
    disabled: {
      true: {
        backgroundColor: fade(palette.tokenBgNeutralLight, 0.25),
        $$labelColor: colors.gray200,
        $$iconColor: colors.gray100,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.tokenBgNeutralDark, 0.25),
          $$labelColor: colors.white,
          $$iconColor: colors.gray50,
        },
      },
      false: {},
    },
    ends: {
      card: {},
      pill: {
        borderRadius: 9999,
      },
    },
    size: {
      'x-small': {
        '@notDesktop': {
          minWidth: '$24',
          heightAll: '$20',
        },

        '@desktop': {
          minWidth: '$20',
          heightAll: '$16',
          paddingY: '$2',
        },
      },
      small: {
        paddingY: '$2',

        '@notDesktop': {
          minWidth: '$28',
          heightAll: '$24',
        },

        '@desktop': {
          minWidth: '$24',
          heightAll: '$20',
        },
      },
      medium: {
        paddingY: '$2',

        '@notDesktop': {
          minWidth: '$32',
          heightAll: '$28',
        },

        '@desktop': {
          minWidth: '$28',
          heightAll: '$24',
        },
      },
      large: {
        paddingY: '$4',

        '@notDesktop': {
          minWidth: '$36',
          heightAll: '$32',
        },

        '@desktop': {
          minWidth: '$32',
          heightAll: '$28',
        },
      },
    },
    variant: {
      alternative: {
        ...getVariantStyles('alternative'),
      },
      brand: {
        ...getVariantStyles('brand'),
      },
      attention: {
        ...getVariantStyles('attention'),
      },
      negative: {
        ...getVariantStyles('negative'),
      },
      neutral: {
        ...getVariantStyles('neutral'),
      },
      positive: {
        ...getVariantStyles('positive'),
      },
    },
    internal: {
      true: {
        ...getVariantStyles('neutral', true),
      },
    },
  },

  compoundVariants: [
    {
      ends: 'card',
      size: 'x-small',
      css: {
        paddingX: '$2',
        borderRadius: '$4',
      },
    },
    {
      ends: 'card',
      size: 'small',
      css: {
        paddingX: '$4',
        borderRadius: '$6',
      },
    },
    {
      ends: 'card',
      size: 'medium',
      css: {
        paddingX: '$6',
        borderRadius: '$8',
      },
    },
    {
      ends: 'card',
      size: 'large',
      css: {
        paddingX: '$8',
        borderRadius: '$10',
      },
    },
    {
      ends: 'pill',
      size: 'x-small',
      css: {
        paddingX: '$4',
      },
    },
    {
      ends: 'pill',
      size: 'small',
      css: {
        paddingX: '$6',
      },
    },
    {
      ends: 'pill',
      size: 'medium',
      css: {
        paddingX: '$8',
      },
    },
    {
      ends: 'pill',
      size: 'large',
      css: {
        paddingX: '$10',
      },
    },
  ],
});

const getBadgePreset = (size: BadgeSize) => {
  if (size === 'x-small') return 'caption';
  if (size === 'small') return 'small';
  return 'body';
};

export function Badge<Tag extends React.ElementType>({
  as = 'div' as Tag,
  arrangement = 'leading-label',
  children,
  ends,
  icon,
  button,
  internal,
  variant,
  size,
  onClick,
  ref,
  width,
  elide = true,
  ...remaining
}: PolymorphicComponentProps<Tag, BadgeProps>) {
  const controlSize = useControlSize(size, 'medium') as BadgeSize;
  const badgeVariant = useVariant(variant) as VariantProp;
  const badgeEnds = useBadgeEnds(ends, 'card');
  const rowSelected = useTableRowSelected();

  const renderBadge = () => (
    <BadgeContainer
      {...remaining}
      ref={ref}
      internal={internal}
      as={as}
      onClick={onClick}
      clickable={onClick !== undefined}
      ends={badgeEnds}
      size={controlSize}
      variant={rowSelected && badgeVariant === 'neutral' ? 'brand' : badgeVariant}
      style={{ width }}
      elide={elide}
    >
      <AlignStack
        align="center"
        justify="center"
        direction={arrangement === 'leading-label' ? 'row' : 'row-reverse'}
        gap={4}
        preset={getBadgePreset(controlSize)}
        end={
          (icon || button) && (
            <>
              {icon && <BadgeIcon icon={icon} size={controlSize} />}
              {button && button}
            </>
          )
        }
      >
        {arrangement !== 'hidden-label' && children && (
          <BadgeLabel
            fontVariantNumeric="tabular"
            size={size}
            weight={
              getBadgePreset(controlSize) === 'caption' || getBadgePreset(controlSize) === 'small'
                ? 'extra-bold'
                : 'bold'
            }
            whitespace="no-wrap"
            elide={elide}
          >
            {children}
          </BadgeLabel>
        )}
      </AlignStack>
    </BadgeContainer>
  );

  if (arrangement === 'hidden-label') {
    return <Tooltip contents={children}>{renderBadge()}</Tooltip>;
  }

  return renderBadge();
}
