import React from 'react';

import type { ControlSize, ControlVariant } from '../shared/types';
import { ControlSizeProvider } from '../../common/control_size';
import { ControlVariantProvider } from '../../common/control_variant';
import { BaseInputContainer } from '../../components/BaseInput/BaseInput';
import { SelectContainer, SelectTrigger } from '../../components/Select/SelectTrigger';
import { styled } from '../../stitches.config';
import { ButtonContainer } from '../Button/Button';

const resetChildSelector = `
& ${ButtonContainer}:not(:focus, :focus-visible, :focus-within), 
& ${SelectContainer} ${SelectTrigger}:not(:focus, :focus-visible, :focus-within), 
& > ${SelectTrigger}:not(:focus, :focus-visible, :focus-within), 
& ${BaseInputContainer}:not(:focus, :focus-visible, :focus-within)`;

const firstChildSelector = `
& ${ButtonContainer}:first-child:not(:focus, :focus-visible, :focus-within), 
& ${SelectContainer}:first-child ${SelectTrigger} button:not(:focus, :focus-visible, :focus-within), 
& > ${SelectTrigger}:first-child:not(:focus, :focus-visible, :focus-within), 
& ${BaseInputContainer}:first-child:not(:focus, :focus-visible, :focus-within)`;

const lastChildSelector = `
& ${ButtonContainer}:last-child, 
& ${SelectContainer}:last-child ${SelectTrigger}:not(:focus, :focus-visible, :focus-within), 
& > ${SelectTrigger}:last-child:not(:focus, :focus-visible, :focus-within), 
& ${BaseInputContainer}:last-child:not(:focus, :focus-visible, :focus-within)`;

const ControlGroupContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',

  variants: {
    direction: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
    },
    ghost: {
      true: {},
      false: {},
    },
    relation: {
      joined: {},
      separate: {},
    },
    size: {
      'x-small': {},
      small: {},
      medium: {},
      large: {},
      'x-large': {},
    },
    wrap: {
      wrap: {
        flexWrap: 'wrap',
      },
      'no-wrap': {
        flexWrap: 'nowrap',
      },
    },
  },

  compoundVariants: [
    {
      ghost: false,
      relation: 'joined',
      css: {
        [resetChildSelector]: {
          borderRadius: 0,
        },
      },
    },
    {
      relation: 'separate',
      size: 'x-small',
      css: {
        '@notDesktop': {
          gap: '$6',
        },

        '@desktop': {
          gap: '$4',
        },
      },
    },
    {
      relation: 'separate',
      size: 'small',
      css: {
        '@notDesktop': {
          gap: '$8',
        },

        '@desktop': {
          gap: '$6',
        },
      },
    },
    {
      relation: 'separate',
      size: 'medium',
      css: {
        '@notDesktop': {
          gap: '$10',
        },

        '@desktop': {
          gap: '$8',
        },
      },
    },
    {
      relation: 'separate',
      size: 'large',
      css: {
        '@notDesktop': {
          gap: '$12',
        },

        '@desktop': {
          gap: '$10',
        },
      },
    },
    {
      relation: 'separate',
      size: 'x-large',
      css: {
        '@notDesktop': {
          gap: '$14',
        },

        '@desktop': {
          gap: '$12',
        },
      },
    },
    {
      direction: 'row',
      relation: 'joined',
      size: 'x-small',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$6',
          borderBottomLeftRadius: '$6',
        },

        [lastChildSelector]: {
          borderTopRightRadius: '$6',
          borderBottomRightRadius: '$6',
        },
      },
    },
    {
      direction: 'row',
      relation: 'joined',
      size: 'small',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$8',
          borderBottomLeftRadius: '$8',
        },

        [lastChildSelector]: {
          borderTopRightRadius: '$8',
          borderBottomRightRadius: '$8',
        },
      },
    },
    {
      direction: 'row',
      relation: 'joined',
      size: 'medium',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$8',
          borderBottomLeftRadius: '$8',
        },

        [lastChildSelector]: {
          borderTopRightRadius: '$8',
          borderBottomRightRadius: '$8',
        },
      },
    },
    {
      direction: 'row',
      relation: 'joined',
      size: 'large',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$10',
          borderBottomLeftRadius: '$10',
        },

        [lastChildSelector]: {
          borderTopRightRadius: '$10',
          borderBottomRightRadius: '$10',
        },
      },
    },
    {
      direction: 'row',
      relation: 'joined',
      size: 'x-large',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$10',
          borderBottomLeftRadius: '$10',
        },

        [lastChildSelector]: {
          borderTopRightRadius: '$10',
          borderBottomRightRadius: '$10',
        },
      },
    },

    {
      direction: 'column',
      relation: 'joined',
      size: 'x-small',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$6',
          borderTopRightRadius: '$6',
        },

        [lastChildSelector]: {
          borderBottomLeftRadius: '$6',
          borderBottomRightRadius: '$6',
        },
      },
    },
    {
      direction: 'column',
      relation: 'joined',
      size: 'small',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$8',
          borderTopRightRadius: '$8',
        },

        [lastChildSelector]: {
          borderBottomLeftRadius: '$8',
          borderBottomRightRadius: '$8',
        },
      },
    },
    {
      direction: 'column',
      relation: 'joined',
      size: 'medium',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$8',
          borderTopRightRadius: '$8',
        },

        [lastChildSelector]: {
          borderBottomLeftRadius: '$8',
          borderBottomRightRadius: '$8',
        },
      },
    },
    {
      direction: 'column',
      relation: 'joined',
      size: 'large',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$10',
          borderTopRightRadius: '$10',
        },

        [lastChildSelector]: {
          borderBottomLeftRadius: '$10',
          borderBottomRightRadius: '$10',
        },
      },
    },
    {
      direction: 'column',
      relation: 'joined',
      size: 'x-large',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$10',
          borderTopRightRadius: '$10',
        },

        [lastChildSelector]: {
          borderBottomLeftRadius: '$10',
          borderBottomRightRadius: '$10',
        },
      },
    },
  ],
});

export type ControlGroupProps = {
  children: React.ReactNode;
  direction?: 'row' | 'column';
  ghost?: boolean;
  size?: ControlSize;
  relation?: 'joined' | 'separate';
  variant?: ControlVariant;
  wrap?: 'wrap' | 'no-wrap';
};

export function ControlGroup({
  children,
  direction = 'row',
  ghost = false,
  relation = 'joined',
  size = 'medium',
  variant,
  wrap,
  ...remaining
}: ControlGroupProps) {
  return (
    <ControlGroupContainer
      direction={direction}
      ghost={ghost}
      size={size}
      relation={relation}
      wrap={wrap}
      {...remaining}
    >
      <ControlSizeProvider value={size}>
        <ControlVariantProvider value={variant}>{children}</ControlVariantProvider>
      </ControlSizeProvider>
    </ControlGroupContainer>
  );
}
