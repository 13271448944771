import type { IconName } from '../../assets/Icon/Icon';
import type { VariantProp } from '../../common/colors';
import type { BadgeArrangement, BadgeSize } from '../../components/Badge/Badge';
import { Badge } from '../../components/Badge/Badge';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { type AccessLevelProp, AccessLevel } from '../../utilities/useAccessLevelContext';

type FeatureBadgeArrangement = 'icon-label' | 'icon-only' | 'label-only';

export type FeatureBadgeProps = {
  arrangement?: FeatureBadgeArrangement;
  size?: BadgeSize;
  type: AccessLevelProp;
};

function getFeatureBadgeArrangement({
  arrangement,
}: {
  arrangement: FeatureBadgeArrangement;
}): BadgeArrangement | undefined {
  switch (arrangement) {
    case 'icon-label':
      return 'leading-icon';
    case 'icon-only':
      return 'hidden-label';
  }

  return undefined;
}

function getFeatureBadgeIcon({ type }: { type: AccessLevelProp }): IconName | undefined {
  switch (type) {
    case AccessLevel.Internal:
      return 'operator';
    case AccessLevel.Alpha:
    case AccessLevel.Beta:
      return type;
  }
  return undefined;
}

function getFeatureBadgeVariant({ type }: { type: AccessLevelProp }): VariantProp | undefined {
  switch (type) {
    case AccessLevel.Alpha:
    case AccessLevel.Beta:
      return 'brand';
  }
  return 'neutral';
}

function getFeatureBadgeLabel({ type }: { type: AccessLevelProp }): string {
  switch (type) {
    case AccessLevel.Internal:
      return 'Internal';
    case AccessLevel.Alpha:
      return 'Alpha';
    case AccessLevel.Beta:
      return 'Beta';
    case AccessLevel.Public:
      return 'Generally available';
  }

  return 'Unknown';
}

export default function FeatureBadge({
  arrangement = 'icon-only',
  size = 'small',
  type,
}: FeatureBadgeProps) {
  if (type === AccessLevel.Public) return null;

  const renderBadge = (
    <Badge
      arrangement={getFeatureBadgeArrangement({ arrangement })}
      icon={arrangement !== 'label-only' ? getFeatureBadgeIcon({ type }) : undefined}
      ends="card"
      internal={type === AccessLevel.Internal}
      size={size}
      variant={getFeatureBadgeVariant({ type })}
    >
      {getFeatureBadgeLabel({ type })}
    </Badge>
  );

  if (arrangement === 'icon-only') {
    return <Tooltip contents={getFeatureBadgeLabel({ type })}>{renderBadge}</Tooltip>;
  }

  return renderBadge;
}
