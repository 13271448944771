import type { AriaRadioGroupProps, AriaRadioProps } from '@react-types/radio';
import { useRadio, useRadioGroup } from '@react-aria/radio';
import { useRadioGroupState } from '@react-stately/radio';
import React, { createContext, useContext, useMemo } from 'react';

import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import {
  markColor,
  raisedDisabledSelectedStyles,
  raisedDisabledStyles,
  raisedEnabledSelectedStyles,
  raisedEnabledStyles,
  selectors,
  transitions,
} from '../shared/styles';

interface RadioContextValue {
  name: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  orientation?: 'horizontal' | 'vertical';
  value?: string | null;
  onChange?: (value: string | null) => void;
  state: ReturnType<typeof useRadioGroupState>;
}

const RadioContext = createContext<RadioContextValue>({
  name: '',
  state: {} as any,
});

const RadioInputMark = styled('div', {
  width: '$6',
  height: '$6',
  borderRadius: '99rem',
  backgroundColor: markColor,
  transition: transitions.control,

  variants: {
    isSelected: {
      true: {
        display: 'flex',
      },
      false: {
        display: 'none',
      },
    },
  },
});

const RadioInputControl = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  widthAll: '$12',
  heightAll: '$12',
  borderRadius: '99rem',
  cursor: 'pointer',
  userSelect: 'none',
  transition: transitions.control,

  [selectors.focus]: {
    outline: 'none',
  },

  variants: {
    isSelected: {
      true: {},
      false: {},
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      isSelected: false,
      isDisabled: false,
      css: raisedEnabledStyles,
    },
    {
      isSelected: true,
      isDisabled: false,
      css: raisedEnabledSelectedStyles,
    },
    {
      isSelected: false,
      isDisabled: true,
      css: raisedDisabledStyles,
    },
    {
      isSelected: true,
      isDisabled: true,
      css: raisedDisabledSelectedStyles,
    },
  ],
});

const RadioInputField = styled('input', {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  opacity: 0,
  cursor: 'pointer',

  variants: {
    isDisabled: {
      true: {
        cursor: 'not-allowed',
      },
      false: {},
    },
  },
});

const RadioInputLabel = styled(Body, {
  fontWeight: '$bold',
  cursor: 'pointer',

  variants: {
    isDisabled: {
      true: {
        color: colors.bodyNeutralLight,
        cursor: 'not-allowed',

        [darkThemeSelector]: {
          color: colors.bodyNeutralDark,
        },
      },
      false: {},
    },
    isSelected: {
      true: {
        color: colors.headingBrandLight,

        [darkThemeSelector]: {
          color: colors.headingBrandDark,
        },
      },
      false: {},
    },
  },
});

const RadioInputContainer = styled('label', {
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
  cursor: 'pointer',
});

interface RadioInputProps extends AriaRadioProps {
  /**
   * The label of the radio input.
   */
  children: React.ReactNode;
  /**
   * The value of the radio input.
   */
  value: string;
}

export function RadioInput({ children, value, ...remaining }: RadioInputProps) {
  const context = useContext(RadioContext);
  const ref = React.useRef<HTMLInputElement>(null);

  const { inputProps, isSelected, isDisabled } = useRadio(
    {
      ...remaining,
      isDisabled: context.isDisabled,
      value,
    },
    context.state,
    ref,
  );

  return (
    <RadioInputContainer>
      <RadioInputControl isDisabled={isDisabled} isSelected={isSelected}>
        <RadioInputMark isSelected={isSelected} />
        <RadioInputField {...inputProps} ref={ref} />
      </RadioInputControl>
      <RadioInputLabel isDisabled={isDisabled} isSelected={isSelected}>
        {children}
      </RadioInputLabel>
    </RadioInputContainer>
  );
}

const RadioGroupLabel = styled(Body, {
  display: 'flex',
  fontWeight: '$bold',
});

const RadioGroupContainer = styled('div', {
  display: 'flex',

  variants: {
    direction: {
      column: {
        flexDirection: 'column',
        gap: '$4',
      },
      row: {
        flexDirection: 'row',
        gap: '$12',
      },
    },
    isDisabled: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
      false: {},
    },
  },
});

interface RadioGroupProps extends AriaRadioGroupProps {
  /**
   * The radio fields of the radio group.
   */
  children: React.ReactNode;
  /**
   * The orientation of the radio group.
   */
  direction?: 'column' | 'row';
  /**
   * The default value of the radio group.
   */
  defaultValue?: string;
  /**
   * The value of the radio group.
   */
  value?: string;
  /**
   * The callback function that is called when the radio group value changes.
   */
  onChange?: (value: string) => void;
  /**
   * Whether the radio group is disabled.
   */
  isDisabled?: boolean;
  /**
   * Whether the radio group is read-only.
   */
  isReadOnly?: boolean;
  /**
   * Whether the radio group is required.
   */
  isRequired?: boolean;
  /**
   * The label of the radio group.
   */
  label?: React.ReactNode;
  /**
   * The name of the radio group.
   */
  name: string;
}

export function RadioGroup({
  children,
  direction = 'column',
  isDisabled,
  isReadOnly,
  isRequired,
  label,
  name,
  ...remaining
}: RadioGroupProps) {
  const state = useRadioGroupState(remaining);
  const { radioGroupProps, labelProps } = useRadioGroup(
    {
      ...remaining,
      orientation: direction === 'row' ? 'horizontal' : 'vertical',
    },
    state,
  );

  const contextValue = useMemo(
    () => ({
      name,
      isDisabled,
      isReadOnly,
      isRequired,
      direction,
      value: state.selectedValue,
      onChange: state.setSelectedValue,
      state,
    }),
    [name, isDisabled, isReadOnly, isRequired, direction, state],
  );

  return (
    <RadioGroupContainer direction={direction} isDisabled={isDisabled} {...radioGroupProps}>
      {label && <RadioGroupLabel {...labelProps}>{label}</RadioGroupLabel>}
      <RadioContext.Provider value={contextValue}>{children}</RadioContext.Provider>
    </RadioGroupContainer>
  );
}
