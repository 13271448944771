import { createContext, useContext, useMemo, useState } from 'react';

import type { BreadcrumbProps } from '../../controls/Breadcrumbs/Breadcrumbs';
import type { HasChildren } from '../../types/components';

export const CrumbsContext = createContext<{
  crumbs?: BreadcrumbProps[] | null;
  setCrumbs?: React.Dispatch<React.SetStateAction<BreadcrumbProps[] | null>>;
}>({});

export function CrumbsContextProvider({ children }: HasChildren) {
  const [crumbs, setCrumbs] = useState<BreadcrumbProps[] | null>(null);

  const value = useMemo(
    () => ({
      crumbs,
      setCrumbs,
    }),
    [crumbs],
  );

  return <CrumbsContext.Provider value={value}>{children}</CrumbsContext.Provider>;
}

export function useCurrentCrumbs() {
  const val = useContext(CrumbsContext);
  return val ?? {};
}
