import type { ReactNode } from 'react';

export function isEmptyNode(children: ReactNode): boolean {
  if (children == null) return true;
  if (Array.isArray(children)) {
    return children.every((c) => isEmptyNode(c));
  }

  return false;
}
