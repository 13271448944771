import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import { Icon } from '../../assets/Icon/Icon';
import { sizing } from '../../common/sizing';
import {
  getVariantStyles,
  variantBackgroundColor,
  variantBodyColor,
  variantHeadingColor,
  variantIconColor,
  variantStrokeColor,
} from '../../common/variant';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import { TerminateButton } from '../../controls/TerminateButton/TerminateButton';
import { fontWeights, styled } from '../../stitches.config';
import { BodySansSizes } from '../../text/Body';
import { SmallSansSizes } from '../../text/Small';
import { Text } from '../../text/Text';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';

const AlertIcon = styled(Icon, {
  color: variantIconColor,

  variants: {
    type: {
      full: {
        width: '$16',
        height: '$16',
      },
      inline: {
        width: '$12',
        height: '$12',
      },
    },
  },
});

const AlertHeading = styled(Text, {
  color: variantHeadingColor,
  wordBreak: 'break-word',

  variants: {
    type: {
      full: {
        ...BodySansSizes,
        fontWeight: fontWeights.bold,
      },
      inline: {
        ...SmallSansSizes,
        fontWeight: fontWeights.extraBold,
      },
    },
  },
});

const AlertCopy = styled(Text, {
  color: variantBodyColor,
  wordBreak: 'break-word',

  variants: {
    type: {
      full: {
        ...BodySansSizes,
      },
      inline: {
        ...SmallSansSizes,
      },
    },
  },
});

const AlertContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
});

const AlertContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  background: variantBackgroundColor,
  strokeAll: variantStrokeColor,

  variants: {
    id: {},
    isDismissed: {
      true: {
        display: 'none',
      },
    },
    relation: {
      standalone: {},
      stacked: {},
    },
    type: {
      full: {
        padding: `$10 ${sizing.contentSides}`,
      },
      inline: {
        padding: `$6 ${sizing.contentSides}`,
      },
    },
    variant: {
      alternative: {
        zIndex: 3,
        ...getVariantStyles('alternative'),
      },
      brand: {
        zIndex: 3,
        ...getVariantStyles('brand'),
      },
      negative: {
        zIndex: 3,
        ...getVariantStyles('negative'),
      },
      attention: {
        zIndex: 3,
        ...getVariantStyles('attention'),
      },
      neutral: {
        zIndex: 1,
        ...getVariantStyles('neutral'),
      },
      positive: {
        zIndex: 3,
        ...getVariantStyles('positive'),
      },
    },
    internal: {
      true: {},
      false: {},
    },
  },

  compoundVariants: [
    {
      relation: 'standalone',
      type: 'full',
      css: {
        borderRadius: '$8',
      },
    },
    {
      relation: 'standalone',
      type: 'inline',
      css: {
        borderRadius: '$6',
      },
    },
    {
      relation: 'stacked',
      type: 'inline',
      css: {
        padding: `$6 ${sizing.contentSides}`,
      },
    },
    {
      relation: 'stacked',
      type: 'full',
      css: {
        padding: `$10 ${sizing.contentSides}`,
      },
    },
    {
      relation: 'standalone',
      type: 'inline',
      css: {
        padding: `$6 $10`,
      },
    },
    {
      relation: 'standalone',
      type: 'full',
      css: {
        padding: `$10 $16`,
      },
    },
    {
      internal: true,
      css: {
        zIndex: 3,
        ...getVariantStyles('neutral', true),
      },
    },
  ],
});

export type AlertRelation = 'stacked' | 'standalone';
export type AlertVariant =
  | 'alternative'
  | 'attention'
  | 'brand'
  | 'negative'
  | 'neutral'
  | 'positive';

export type AlertProps = {
  /**
   * The more detailed information regarding the notice, no value disables no copy.
   */
  copy?: React.ReactNode;
  /**
   * Provide an accessible label for the dismiss action of the alert.
   */
  dismissLabel?: string;
  /**
   * Pass in the onClick event for dismissing the alert.
   */
  dismissOnClick?: (event: any) => void;
  /**
   * A simple heading for the notice, no value disables no heading.
   */
  heading?: React.ReactNode;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Pass in an ID to make sure the Alert is identifiable.
   */
  id?: React.ReactNode;
  /**
   * Set whether the alert is internal.
   */
  internal?: boolean;
  /**
   * Set whether the alert has been dismissed.
   */
  isDismissed?: boolean;
  /**
   * Set whether the radius should exist or be squared off.
   */
  relation?: AlertRelation;
  /**
   * Actions to be displayed at the bottom of the alert.
   */
  trailingButtons?: React.ReactNode;
  /**
   * Actions to be displayed at the top-right of the alert.
   */
  shoulderButtons?: React.ReactNode;
  /**
   * Determine if the component should be the full or inline type.
   */
  type?: 'full' | 'inline';
  /**
   * Set the most appropriate variant of the component for your use.
   */
  variant?: AlertVariant;

  ref?: React.Ref<HTMLDivElement | null>;
};

export function Alert({
  copy,
  dismissLabel = 'Dismiss',
  dismissOnClick,
  heading,
  icon,
  id,
  internal,
  isDismissed,
  relation = 'standalone',
  ref,
  shoulderButtons,
  trailingButtons,
  type = 'full',
  variant = 'neutral',
  ...remaining
}: AlertProps) {
  return (
    <AlertContainer
      isDismissed={isDismissed}
      variant={variant}
      relation={relation}
      type={type}
      internal={internal}
      ref={ref}
      {...remaining}
    >
      <AlignStack
        direction="row"
        gap={type === 'full' ? 8 : 6}
        preset={type === 'full' ? 'body' : 'small'}
        start={icon && <AlertIcon icon={icon} type={type} />}
        end={
          (shoulderButtons || dismissOnClick) && (
            <ControlGroup relation="separate" size="small">
              {shoulderButtons}
              {dismissOnClick && (
                <TerminateButton
                  onClick={dismissOnClick}
                  icon="cross"
                  label={dismissLabel}
                  variant={variant}
                />
              )}
            </ControlGroup>
          )
        }
      >
        {(heading || copy || trailingButtons) && (
          <AlertContent>
            {heading && <AlertHeading type={type}>{heading}</AlertHeading>}
            {copy && <AlertCopy type={type}>{copy}</AlertCopy>}
            {trailingButtons && (
              <ControlGroup relation="separate" size="small">
                {trailingButtons}
              </ControlGroup>
            )}
          </AlertContent>
        )}
      </AlignStack>
    </AlertContainer>
  );
}
