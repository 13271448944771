import { merge } from 'lodash-es';

import type { AlignTypes } from '../shared/Align';
import type { FontSizeProp } from '../shared/FontSize';
import type { JustifyTypes } from '../shared/Justify';
import type { LineHeightProp } from '../shared/LineHeight';
import type { WidthTypes } from '../shared/Width';
import { styled } from '../../stitches.config';
import { BodySansSizes } from '../../text/Body';
import { CaptionSansSizes } from '../../text/Caption';
import { HeadingSansSizes } from '../../text/Heading';
import { LargeSansSizes } from '../../text/Large';
import { SmallSansSizes } from '../../text/Small';
import { SubheadingSansSizes } from '../../text/Subheading';
import { TitleSansSizes } from '../../text/Title';
import { alignCSS } from '../shared/Align';
import { fontSizeCSS } from '../shared/FontSize';
import { justifyCSS } from '../shared/Justify';
import { lineHeightCSS } from '../shared/LineHeight';
import { marginCSS } from '../shared/Margin';
import { type SpacingScale } from '../shared/sizes';
import { spacingCSS } from '../shared/Spacing';
import { widthCSS } from '../shared/Width';

const AlignStackLocked = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  variants: {
    direction: {
      column: {},
      'column-reverse': {},
      row: {
        heightAll: '1lh',
      },
      'row-reverse': {
        heightAll: '1lh',
      },
    },
    boxBound: {
      true: {
        widthAll: '1lh',
      },
    },
  },
});

const AlignStackElements = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minWidth: 0,
});

const AlignStackContainer = styled('div', {
  display: 'flex',
  width: '100%',
  minWidth: 0,

  variants: {
    direction: {
      column: {
        flexDirection: 'column',
      },
      'column-reverse': {
        flexDirection: 'column-reverse',
      },
      row: {
        flexDirection: 'row',
      },
      'row-reverse': {
        flexDirection: 'row-reverse',
      },
    },
    preset: {
      body: {
        ...BodySansSizes,
      },
      caption: {
        ...CaptionSansSizes,
      },
      heading: {
        ...HeadingSansSizes,
      },
      large: {
        ...LargeSansSizes,
      },
      small: {
        ...SmallSansSizes,
      },
      subheading: {
        ...SubheadingSansSizes,
      },
      title: {
        ...TitleSansSizes,
      },
    },
  },
});

type AlignStackDirectionProp = 'column' | 'column-reverse' | 'row' | 'row-reverse';
export type AlignStackPresetProp =
  | 'body'
  | 'caption'
  | 'heading'
  | 'large'
  | 'small'
  | 'subheading'
  | 'title';

export type AlignStackProps = {
  align?: AlignTypes;
  boxBound?: boolean;
  direction?: AlignStackDirectionProp;
  children?: React.ReactNode;
  end?: React.ReactNode;
  fontSize?: FontSizeProp;
  gap?:
    | SpacingScale
    | {
        start?: SpacingScale;
        end?: SpacingScale;
      };
  justify?: JustifyTypes;
  lineHeight?: LineHeightProp;
  start?: React.ReactNode;
  preset?: AlignStackPresetProp;
  width?: WidthTypes;
};

const getAlign = (align: AlignTypes | undefined, direction: AlignStackDirectionProp) => {
  if (!align) {
    if (direction === 'row' || direction === 'row-reverse') {
      return 'start';
    }
    return 'center';
  }
  return align;
};

const getJustify = (justify: JustifyTypes | undefined, direction: AlignStackDirectionProp) => {
  if (!justify) {
    if (direction === 'row' || direction === 'row-reverse') {
      return 'start';
    }
    return 'center';
  }
  return justify;
};

const getStartGap = (gap: SpacingScale, direction: AlignStackDirectionProp) => {
  if (direction === 'row-reverse') {
    return { left: gap };
  }
  if (direction === 'column') {
    return { bottom: gap };
  }
  if (direction === 'column-reverse') {
    return { top: gap };
  }
  return { right: gap };
};

const getEndGap = (gap: SpacingScale, direction: AlignStackDirectionProp) => {
  if (direction === 'row-reverse') {
    return { right: gap };
  }
  if (direction === 'column') {
    return { top: gap };
  }
  if (direction === 'column-reverse') {
    return { bottom: gap };
  }
  return { left: gap };
};

export function AlignStack({
  align,
  boxBound = false,
  direction = 'row',
  children,
  end,
  fontSize,
  gap,
  justify,
  lineHeight,
  start,
  preset,
  width,
  ...remaining
}: AlignStackProps) {
  const hasGapObject = gap && typeof gap === 'object';

  const containerCSS = merge(
    alignCSS(getAlign(align, direction)),
    justifyCSS(getJustify(justify, direction)),
    spacingCSS(hasGapObject ? undefined : gap),
    widthCSS(width),
    !preset ? fontSizeCSS(fontSize) : undefined,
    !preset ? lineHeightCSS(lineHeight) : undefined,
  );

  const lockedStartCSS =
    hasGapObject && gap.start ? merge(marginCSS(getStartGap(gap.start, direction))) : undefined;
  const lockedEndCSS =
    hasGapObject && gap.end ? merge(marginCSS(getEndGap(gap.end, direction))) : undefined;

  const elementsCSS = merge(
    alignCSS(getAlign(align, direction)),
    justifyCSS(getJustify(justify, direction)),
  );

  return (
    <AlignStackContainer css={containerCSS} direction={direction} preset={preset} {...remaining}>
      {start && (
        <AlignStackLocked boxBound={boxBound} direction={direction} css={lockedStartCSS}>
          {start}
        </AlignStackLocked>
      )}
      {children && <AlignStackElements css={elementsCSS}>{children}</AlignStackElements>}
      {end && (
        <AlignStackLocked boxBound={boxBound} direction={direction} css={lockedEndCSS}>
          {end}
        </AlignStackLocked>
      )}
    </AlignStackContainer>
  );
}
