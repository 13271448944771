import type { TextContextProp, TextVariantProp, TextWeightProp } from './Text';
import { Body } from './Body';
import { Caption } from './Caption';
import { Heading } from './Heading';
import { Large } from './Large';
import { Small } from './Small';
import { Subheading } from './Subheading';
import { Title } from './Title';

export type DynamicTextPropType =
  | 'caption'
  | 'small'
  | 'body'
  | 'heading'
  | 'large'
  | 'title'
  | 'subheading';

export type DynamicTextProps = {
  /**
   * The context of the text, whether it's a heading or body text.
   */
  context?: TextContextProp;
  /**
   * The children of the text.
   */
  children: React.ReactNode;
  /**
   * Whether the text is internal.
   */
  internal?: boolean;
  /**
   * Whether the text should be truncated (overflow with an ellipsis).
   */
  truncate?: boolean;
  /**
   * The type of text, whether it's a caption or body text.
   */
  type: DynamicTextPropType;
  /**
   * The variant of the text, whether it's a neutral or brand text.
   */
  variant?: TextVariantProp;
  /**
   * The weight of the text.
   */
  weight?: TextWeightProp;
};

function DynamicTextElement({
  context = 'body',
  internal = false,
  truncate = false,
  type,
  variant,
  weight,
  ...remaining
}: Required<DynamicTextProps>) {
  switch (type) {
    case 'caption':
      return (
        <Caption
          context={context}
          internal={internal}
          variant={variant}
          weight={weight}
          truncate={truncate}
          {...remaining}
        />
      );
    case 'small':
      return (
        <Small
          context={context}
          internal={internal}
          variant={variant}
          weight={weight}
          truncate={truncate}
          {...remaining}
        />
      );
    case 'body':
      return (
        <Body
          context={context}
          internal={internal}
          variant={variant}
          weight={weight}
          truncate={truncate}
          {...remaining}
        />
      );
    case 'subheading':
      return (
        <Subheading
          context={context}
          internal={internal}
          variant={variant}
          truncate={truncate}
          {...remaining}
        />
      );
    case 'large':
      return (
        <Large
          context={context}
          internal={internal}
          variant={variant}
          weight={weight}
          truncate={truncate}
          {...remaining}
        />
      );
    case 'heading':
      return (
        <Heading
          context={context}
          internal={internal}
          variant={variant}
          truncate={truncate}
          {...remaining}
        />
      );
    case 'title':
      return (
        <Title
          context={context}
          internal={internal}
          variant={variant}
          truncate={truncate}
          {...remaining}
        />
      );
  }
}
export function DynamicText({
  children,
  context = 'body',
  internal = false,
  truncate = false,
  type,
  variant = 'neutral',
  weight = 'regular',
  ...remaining
}: DynamicTextProps) {
  return (
    <DynamicTextElement
      context={context}
      internal={internal}
      truncate={truncate}
      type={type}
      variant={variant}
      weight={weight}
      {...remaining}
    >
      {children}
    </DynamicTextElement>
  );
}
