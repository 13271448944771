import type { SVGAttributes } from 'react';
import { ProductIconSVG } from 'atto-svgs';
import React from 'react';

import type { SpacingValue } from '../../common/sizing';
import { styled } from '../../stitches.config';

export type ProductIconSize = SpacingValue;

export const ProductIcons = {
  access: ProductIconSVG.Access,
  'auto-vpn': ProductIconSVG.AutoVPN,
  cellular: ProductIconSVG.Cellular,
  command: ProductIconSVG.Command,
  connect: ProductIconSVG.Connect,
  hub: ProductIconSVG.Hub,
  network: ProductIconSVG.Network,
  operator: ProductIconSVG.Operator,
  spend: ProductIconSVG.Spend,
};

export const ProductIconValues = Object.keys(ProductIcons);
export type ProductIconName = keyof typeof ProductIcons;
export const ProductIconNames = Object.keys(ProductIcons) as ProductIconName[];

export interface ProductIconProps {
  /**
   * Set which piece of product to display.
   */
  icon: ProductIconName;
  /**
   * Set a size of the component.
   */
  size?: ProductIconSize;
}

const BaseSVG = styled('svg', {
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
});

export function ProductIcon({ icon, size = 16, ...remaining }: ProductIconProps) {
  const NamedProductIcon = ProductIcons[icon] as
    | React.ComponentType<SVGAttributes<SVGSVGElement>>
    | undefined;

  if (!NamedProductIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${icon}`);
    return null;
  }

  return <BaseSVG as={NamedProductIcon} width={size} height={size} {...remaining} />;
}
