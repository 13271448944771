import type { ComponentProps, RefObject } from 'react';
import { merge } from 'lodash-es';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import type { DynamicIconName } from '../../assets/DynamicIcon/DynamicIcon';
import type { TabProps } from '../../components/Tabs/Tabs';
import type { BreadcrumbProps, BreadcrumbsPropHome } from '../../controls/Breadcrumbs/Breadcrumbs';
import type { GutterProp } from '../../utilities/shared/Gutter';
import type { MaxWidthTypes } from '../../utilities/shared/MaxWidth';
import type { MinWidthTypes } from '../../utilities/shared/MinWidth';
import type { SpacingScale } from '../../utilities/shared/sizes';
import type { WidthTypes } from '../../utilities/shared/Width';
import type { Breakpoint } from '../../utilities/useViewport';
import type {
  ShellPropContentMode,
  ShellPropLayoutMode,
  ShellPropSize,
  ShellPropWidthMode,
} from './utils';
import { Divider } from '../../assets/Divider/Divider';
import { DynamicIcon } from '../../assets/DynamicIcon/DynamicIcon';
import { backgrounds } from '../../common/backgrounds';
import { sizing } from '../../common/sizing';
import { zIndexes } from '../../common/z_indexes';
import { Badge } from '../../components/Badge/Badge';
import { BadgeGroup } from '../../components/Badge/BadgeGroup';
import { useCurrentCrumbs } from '../../components/Providers/CrumbsContextProvider';
import { Tabs } from '../../components/Tabs/Tabs';
import { Breadcrumbs } from '../../controls/Breadcrumbs/Breadcrumbs';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import { TerminateButton } from '../../controls/TerminateButton/TerminateButton';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { BodySansSizes } from '../../text/Body';
import { SmallSansSizes } from '../../text/Small';
import { Text } from '../../text/Text';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import { maxWidthCSS } from '../../utilities/shared/MaxWidth';
import { minWidthCSS } from '../../utilities/shared/MinWidth';
import { spacingCSS } from '../../utilities/shared/Spacing';
import { widthCSS } from '../../utilities/shared/Width';
import {
  HorizontalScrollShadow,
  useHorizontalShadowOnScroll,
  useVerticalShadowOnScroll,
  VerticalScrollShadow,
} from '../../utilities/useShadowOnScroll';
import { StuckProvider } from '../../utilities/useStuck';
import { useViewport } from '../../utilities/useViewport';
import {
  ShellContentModeProvider,
  ShellLayoutModeProvider,
  ShellMaxWidthProvider,
  ShellMinWidthProvider,
  ShellSizeProvider,
  ShellWidthModeProvider,
  ShellWidthProvider,
  useShellContentMode,
  useShellLayoutMode,
  useShellMaxWidth,
  useShellMinWidth,
  useShellSize,
  useShellWidth,
  useShellWidthMode,
} from './utils';

const ShellHeaderIcon = styled(DynamicIcon, {
  display: 'flex',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },

  variants: {
    hasSize: {
      'x-small': {
        width: '$12',
        height: '$12',
      },
      medium: {
        width: '$16',
        height: '$16',
      },
    },
  },
});

const ShellHeaderHeading = styled(Text, {
  maxWidth: '100%',
  truncate: true,
  color: colors.headingNeutralLight,
  fontWeight: fontWeights.bold,

  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },

  variants: {
    size: {
      'x-small': {
        ...SmallSansSizes,
      },
      medium: {
        ...BodySansSizes,
      },
    },
  },
});

const ShellHeaderStartScroll = styled('div', HorizontalScrollShadow, {
  minWidth: 0,
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',

  variants: {
    hasBreacrumb: {
      true: {},
      false: {},
    },
    hasEnd: {
      true: {},
      false: {},
    },
    size: {
      'x-small': {
        '@notDesktop': {
          gap: '$6',
          padding: `$8 $4 $8 ${sizing.contentSides}`,
        },

        '@desktop': {
          gap: '$4',
          minHeight: '$32',
          padding: `$4 ${sizing.contentSides}`,
        },
      },
      medium: {
        '@notDesktop': {
          gap: '$8',
          padding: `$12 $6 $12 ${sizing.sides}`,
        },

        '@desktop': {
          gap: '$6',
          padding: `$8 $${sizing.primary}`,
        },
      },
    },
  },
  compoundVariants: [
    {
      size: 'x-small',
      hasBreacrumb: true,
      css: {
        '@desktop': {
          paddingLeft: `$${sizing.secondary - 6}`,
        },
      },
    },
    {
      size: 'medium',
      hasBreacrumb: true,
      css: {
        '@desktop': {
          paddingLeft: `$${sizing.primary - 6}`,
        },
      },
    },
    {
      size: 'x-small',
      hasEnd: true,
      css: {
        paddingRight: '$6',
      },
    },
    {
      size: 'medium',
      hasEnd: true,
      css: {
        paddingRight: '$10',
      },
    },
  ],
});

const ShellHeaderStart = styled('div', {
  minWidth: 0,
  position: 'relative',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  overflow: 'hidden',
});

const ShellHeaderEnd = styled('div', {
  minWidth: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',

  variants: {
    hasStart: {
      true: {},
      false: {},
    },
    size: {
      'x-small': {
        padding: sizing.contentSidesOnly,

        '@notDesktop': {
          gap: '$6',
        },

        '@desktop': {
          gap: '$4',
        },
      },
      medium: {
        padding: sizing.sidesOnly,

        '@notDesktop': {
          gap: '$8',
        },

        '@desktop': {
          gap: '$6',
        },
      },
    },
  },
  compoundVariants: [
    {
      size: 'x-small',
      hasStart: true,
      css: {
        paddingLeft: '$4',
      },
    },
    {
      size: 'medium',
      hasStart: true,
      css: {
        paddingLeft: '$4',
      },
    },
  ],
});

const ShellHeaderEnds = styled('div', {
  position: 'relative',
  minWidth: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  '&::after': {
    content: '',
    position: 'absolute',
    bottom: '-0.5px',
    display: 'block',
    height: '$1',
    marginTop: '-0.5px',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },

  variants: {
    size: {
      'x-small': {
        '@notDesktop': {
          minHeight: '$40',
        },

        '@desktop': {
          minHeight: '$32',
        },

        '&::after': {
          right: sizing.contentSides,
          left: sizing.contentSides,
        },
      },
      medium: {
        '@notDesktop': {
          minHeight: '$52',
        },

        '@desktop': {
          minHeight: '$44',
        },

        '&::after': {
          right: sizing.sides,
          left: sizing.sides,
        },
      },
    },
  },
});

const ShellHeaderToolbar = styled(ShellHeaderEnds);

const ShellHeaderNavigationDivider = styled(Divider, {
  padding: '$4',
});

const ShellHeaderNavigation = styled(ShellHeaderEnds);
const ShellHeaderNavigationStart = styled(ShellHeaderStart);
const ShellHeaderNavigationStartScroll = styled(ShellHeaderStartScroll, {
  variants: {
    hasEnd: {
      true: {},
      false: {},
    },
    hasOldTabs: {
      true: {},
      false: {},
    },
    size: {
      'x-small': {
        '@notDesktop': {
          gap: '$16',
          padding: `$6 ${sizing.contentSides}`,
        },

        '@desktop': {
          gap: '$12',
          minHeight: '$32',
          padding: `$4 ${sizing.contentSides}`,
        },
      },
      medium: {
        '@notDesktop': {
          gap: '$20',
          padding: `$10 ${sizing.sides}`,
        },

        '@desktop': {
          gap: '$16',
          padding: `$8 ${sizing.sides}`,
        },
      },
    },
  },
  compoundVariants: [
    {
      size: 'x-small',
      hasEnd: true,
      css: {
        paddingRight: '$6',
      },
    },
    {
      size: 'medium',
      hasEnd: true,
      css: {
        paddingRight: '$10',
      },
    },
    {
      hasOldTabs: true,
      css: {
        '@notDesktop': {
          gap: '$4',
        },

        '@desktop': {
          gap: '$4',
        },
      },
    },
  ],
});
const ShellHeaderNavigationEnd = styled(ShellHeaderEnd);

const ShellHeaderViews = styled(ShellHeaderEnds);

const ShellHeaderWidth = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const ShellHeaderContainer = styled('div', {
  gridArea: 'shell-header',
  position: 'relative',
  zIndex: 2,
  minWidth: 0,
  display: 'flex',
  flexDirection: 'column',

  variants: {
    size: {
      'x-small': {},
      medium: {},
    },
    widthMode: {
      centered: {
        alignItems: 'center',
      },
      full: {},
    },
  },
});

export type ShellHeaderProps = {
  /**
   * Provide any actions for your pane.
   */
  actions?: React.ReactNode;
  /**
   * Inline a back button, pass in the onClick event on this prop.
   */
  back?: () => void;
  /**
   * Show relevant badges alongside the `heading`.
   */
  badges?: React.ReactNode;
  /**
   * Provide a label for the close button.
   */
  closeButtonAriaLabel?: string;
  /**
   * Provide any actions for the displayed content.
   */
  contentActions?: React.ReactNode;
  /**
   * Show the total count of items.
   */
  count?: React.ReactNode;
  /**
   * Provide a list of breadcrumbs.
   */
  crumbs?: BreadcrumbProps[];
  /**
   * Provide a heading for your pane.
   */
  heading?: React.ReactNode;
  /**
   * Inline a home button, pass in the onClick event on this prop.
   */
  home?: BreadcrumbsPropHome;
  /**
   * Provide an icon for your pane.
   */
  icon?: DynamicIconName;
  /**
   * Provide a handler and opt into adding a close icon to the right.
   */
  onClose?: React.ReactEventHandler;
  /**
   * Provide any subtabs in addition to tabs.
   */
  secondaryTabs?: TabProps[];
  /**
   * Allows for a select interface to switch between objects.
   */
  switcher?: React.ReactNode;
  /**
   * Provide any tabs you want to display.
   */
  tabs?: TabProps[];
  /**
   * Provide the views and filters utilizing the `views` prop.
   */
  views?: React.ReactNode;
  /**
   * Provide a layout mode for your shell.
   * Note: Automatically provided from the `Shell` component, you likely don't need to provide this.
   */
  layoutMode?: ShellPropLayoutMode;
  /**
   * Provide a size for your shell.
   * Note: Automatically provided from the `Shell` component, you likely don't need to provide this.
   */
  size?: ShellPropSize;
  // Deprecated
  oldTabs?: React.ReactNode;
  oldSubtabs?: React.ReactNode;
};

const shellHeaderControlSize = (size: ShellPropSize | undefined, breakpoint: Breakpoint) => {
  if (size === 'x-small') return 'x-small';
  if (breakpoint === 'desktop') return 'small';
  return 'medium';
};

function ShellHeaderActions({
  actions,
  onClose,
  closeButtonAriaLabel,
  size,
}: Omit<ShellHeaderProps, 'back' | 'home' | 'crumbs' | 'switcher' | 'tabs' | 'subtabs' | 'views'>) {
  const { breakpoint } = useViewport();
  return (
    <>
      {actions && (
        <ControlGroup size={shellHeaderControlSize(size, breakpoint)} relation="separate">
          {actions}
        </ControlGroup>
      )}
      {onClose && (
        <TerminateButton onClick={onClose} label={closeButtonAriaLabel} variant="neutral" />
      )}
    </>
  );
}

export function ShellHeader({
  actions,
  back,
  badges,
  closeButtonAriaLabel = 'Close',
  contentActions,
  count,
  crumbs,
  heading,
  home,
  icon,
  layoutMode,
  onClose,
  size,
  secondaryTabs,
  switcher,
  tabs,
  views,
  // Deprecated
  oldTabs,
  oldSubtabs,
  ...remaining
}: ShellHeaderProps) {
  const shellLayoutMode = useShellLayoutMode(layoutMode, 'compact');
  const isDetailed = shellLayoutMode === 'detailed';
  const shellSize = useShellSize(size, 'medium');
  const shellWidth = useShellWidth();
  const shellMaxWidth = useShellMaxWidth();
  const shellMinWidth = useShellMinWidth();
  const shellWidthMode = useShellWidthMode();
  const { breakpoint } = useViewport();

  const toolbarRef = useRef<HTMLDivElement>(null);
  const {
    getBoxShadow: getToolbarShadow,
    onScrollHandler: onToolbarScrollHandler,
    handleTargetChange: handleToolbarTargetChange,
  } = useHorizontalShadowOnScroll('both');
  const handleResizeToolbar = useCallback(() => {
    if (toolbarRef.current) {
      handleToolbarTargetChange(toolbarRef.current);
    }
  }, [handleToolbarTargetChange]);
  useEffect(() => {
    handleResizeToolbar();
    window.addEventListener('resize', handleResizeToolbar);
    return () => {
      window.removeEventListener('resize', handleResizeToolbar);
    };
  }, [handleResizeToolbar]);

  const navigationRef = useRef<HTMLDivElement>(null);
  const {
    getBoxShadow: getNavigationShadow,
    onScrollHandler: onNavigationScrollHandler,
    handleTargetChange: handleNavigationTargetChange,
  } = useHorizontalShadowOnScroll('both');
  const handleResizeNavigation = useCallback(() => {
    if (navigationRef.current) {
      handleNavigationTargetChange(navigationRef.current);
    }
  }, [handleNavigationTargetChange]);
  useEffect(() => {
    handleResizeNavigation();
    window.addEventListener('resize', handleResizeNavigation);
    return () => {
      window.removeEventListener('resize', handleResizeNavigation);
    };
  }, [handleResizeNavigation]);

  const hasBreadcrumbs =
    isDetailed && breakpoint === 'desktop' && Boolean(back || home || crumbs || switcher);
  const hasToolbarStart = Boolean(hasBreadcrumbs || icon || heading || switcher || count);
  const hasToolbarEnd = Boolean(actions || onClose);
  const hasToolbar = Boolean(hasToolbarStart || hasToolbarEnd);

  const hasNavigationStart = Boolean(tabs || secondaryTabs || oldTabs || oldSubtabs);
  const hasNavigationEnd = Boolean(contentActions);
  const hasNavigation = Boolean(hasNavigationStart || hasNavigationEnd);

  const hasViews = Boolean(views);

  const { setCrumbs } = useCurrentCrumbs();
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (crumbs && setCrumbs) {
      setCrumbs(crumbs);
      return () => {
        setCrumbs(null);
      };
    }
  }, [crumbs, setCrumbs]);

  return (
    (hasBreadcrumbs || hasToolbar || hasNavigation || hasViews) && (
      <ShellHeaderContainer size={shellSize} widthMode={shellWidthMode} {...remaining}>
        <ShellHeaderWidth
          css={merge(
            shellWidth ? widthCSS(shellWidth) : undefined,
            shellMaxWidth ? maxWidthCSS(shellMaxWidth) : undefined,
            shellMinWidth ? minWidthCSS(shellMinWidth) : undefined,
          )}
        >
          {hasToolbar && (
            <ShellHeaderToolbar size={shellSize}>
              {hasToolbarStart && (
                <ShellHeaderStart>
                  <ShellHeaderStartScroll
                    size={shellSize}
                    hasBreacrumb={hasBreadcrumbs}
                    hasEnd={hasToolbarEnd}
                    onScroll={onToolbarScrollHandler}
                    ref={toolbarRef}
                    {...getToolbarShadow}
                  >
                    {hasBreadcrumbs ? (
                      <Breadcrumbs
                        // back={back}
                        // home={home}
                        crumbs={[
                          ...(crumbs || []),
                          ...(switcher
                            ? [
                                {
                                  field: {
                                    field: switcher,
                                    badges,
                                  },
                                },
                              ]
                            : [
                                {
                                  page: {
                                    label: heading,
                                    annotation: count ? `${count}` : null,
                                    selected: true,
                                    badges,
                                  },
                                },
                              ]),
                        ]}
                      />
                    ) : (
                      <AlignStack
                        direction="row"
                        align="center"
                        gap={shellSize === 'medium' ? 6 : 4}
                        preset={shellSize === 'medium' ? 'body' : 'small'}
                        width="fit-content"
                        start={
                          !switcher && icon && <ShellHeaderIcon hasSize={shellSize} icon={icon} />
                        }
                        end={
                          badges || count ? (
                            <BadgeGroup
                              relation="separate"
                              size={shellSize === 'x-small' ? 'small' : 'medium'}
                            >
                              {count && (
                                <Badge
                                  ends="card"
                                  size={shellSize === 'x-small' ? 'x-small' : 'small'}
                                  variant="neutral"
                                >
                                  {count}
                                </Badge>
                              )}
                              {badges}
                            </BadgeGroup>
                          ) : null
                        }
                      >
                        {!switcher && heading && (
                          <ShellHeaderHeading size={shellSize}>{heading}</ShellHeaderHeading>
                        )}
                        {switcher}
                      </AlignStack>
                    )}
                  </ShellHeaderStartScroll>
                </ShellHeaderStart>
              )}
              {hasToolbarEnd && (
                <ShellHeaderEnd size={shellSize} hasStart={hasToolbarStart}>
                  <ShellHeaderActions
                    actions={actions}
                    onClose={onClose}
                    closeButtonAriaLabel={closeButtonAriaLabel}
                    size={shellSize}
                  />
                </ShellHeaderEnd>
              )}
            </ShellHeaderToolbar>
          )}
          {hasNavigation && (
            <ShellHeaderNavigation size={shellSize}>
              <ShellHeaderNavigationStart>
                {hasNavigationStart && (
                  <ShellHeaderNavigationStartScroll
                    size={shellSize}
                    hasEnd={hasNavigationEnd}
                    hasOldTabs={Boolean(oldTabs || oldSubtabs)}
                    onScroll={onNavigationScrollHandler}
                    ref={navigationRef}
                    {...getNavigationShadow}
                  >
                    {(tabs || secondaryTabs) && <Tabs tabs={tabs} secondaryTabs={secondaryTabs} />}
                    {oldTabs && <Tabs>{oldTabs}</Tabs>}
                    {oldTabs && oldSubtabs && (
                      <ShellHeaderNavigationDivider
                        appearance="thick"
                        direction="column"
                        variant="neutral"
                      />
                    )}
                    {oldSubtabs && <Tabs>{oldSubtabs}</Tabs>}
                  </ShellHeaderNavigationStartScroll>
                )}
              </ShellHeaderNavigationStart>
              {hasNavigationEnd && (
                <ShellHeaderNavigationEnd size={shellSize} hasStart={hasNavigationStart}>
                  {contentActions && (
                    <ControlGroup
                      size={shellHeaderControlSize(shellSize, breakpoint)}
                      relation="separate"
                    >
                      {contentActions}
                    </ControlGroup>
                  )}
                </ShellHeaderNavigationEnd>
              )}
            </ShellHeaderNavigation>
          )}
          {hasViews && <ShellHeaderViews>{views}</ShellHeaderViews>}
        </ShellHeaderWidth>
      </ShellHeaderContainer>
    )
  );
}

const ShellContentStuckInnerPosition = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'fit-content',

  variants: {
    stuck: {
      true: {
        background: colors.bgApplicationLight,
        boxShadow: shadows.stuckLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
          boxShadow: shadows.stuckDark,
        },
      },
      false: {
        '&::after': {
          content: '',
          position: 'absolute',
          right: sizing.sides,
          bottom: '-0.5px',
          left: sizing.sides,
          display: 'block',
          height: '$1',
          background: colors.strokeNeutralLight,

          [darkThemeSelector]: {
            background: colors.strokeNeutralDark,
          },
        },
      },
    },
  },
});

const ShellContentStuckInnerContainer = styled('div', {
  gridArea: 'stuck',
  display: 'flex',
  width: '100%',
});

type ShellContentGlanceProps = {
  /**
   * Provide the content for the glance.
   */
  children: React.ReactNode;
  /**
   * Boolean to determine if the element is stuck or not.
   */
  stuck?: boolean;
  /**
   * Ref to the element.
   */
  ref?: React.Ref<HTMLDivElement | null>;
};

export function ShellContentStuckInner({ children, stuck, ref }: ShellContentGlanceProps) {
  return (
    <StuckProvider value={stuck}>
      <ShellContentStuckInnerContainer ref={ref}>
        <ShellContentStuckInnerPosition stuck={stuck}>{children}</ShellContentStuckInnerPosition>
      </ShellContentStuckInnerContainer>
    </StuckProvider>
  );
}

const useShellContentStuck = () => {
  const [isOutOfView, setIsOutOfView] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  // Make the sticky header visible when 70% of the "real" header is out of view.
  useLayoutEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      // ensures that the element is rendered with content without display: none; (a result of AppSkeleton)
      if (ref.current?.clientHeight) {
        setIsOutOfView(!entries[0].isIntersecting);
      }
    };

    const observer = new IntersectionObserver(callback, {
      root: null,
      threshold: 0.3,
      rootMargin: '0px 0px 0px 0px',
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, []);

  return { ref, isOutOfView };
};

const ShellContentStuckZero = styled('div', {
  gridArea: 'stuck',
  position: 'sticky',
  zIndex: zIndexes.paneStuck,
  top: 0,
  height: 0,
  opacity: 0,
  transition: 'opacity 200ms ease-in-out',

  variants: {
    visible: {
      true: {
        display: 'flex',
        opacity: 1,
      },
      false: {
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },
});

export function ShellContentStuck({
  spacing,
  ...props
}: { spacing: SpacingScale } & ShellContentGlanceProps) {
  const { ref, isOutOfView } = useShellContentStuck();

  return (
    <>
      <ShellContentStuckZero
        visible={isOutOfView}
        aria-hidden="true"
        style={{ marginTop: `-${spacing}px` }}
      >
        <ShellContentStuckInner {...props} stuck />
      </ShellContentStuckZero>
      <ShellContentStuckInner {...props} stuck={false} ref={ref} />
    </>
  );
}

const ShellContentGlance = styled('div', {
  position: 'relative',
  display: 'flex',

  '&::after': {
    content: '',
    position: 'absolute',
    right: sizing.sides,
    bottom: '-0.5px',
    left: sizing.sides,
    display: 'block',
    height: '$1',
    background: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      background: colors.strokeNeutralDark,
    },
  },
});

const ShellContentControls = styled('div');

export const ShellContentSpacing = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  variants: {
    contentMode: {
      fit: {
        height: 'fit-content',
      },
      stretch: {
        height: '100%',
      },
    },
    gutter: {
      all: {},
      vertical: {},
      horizontal: {},
      top: {},
      right: {},
      bottom: {},
      left: {},
      none: {
        padding: 0,
      },
    },
    size: {
      'x-small': {},
      medium: {},
    },
  },

  compoundVariants: [
    {
      size: 'x-small',
      gutter: 'all',
      css: {
        padding: sizing.contentSquish,
      },
    },
    {
      size: 'x-small',
      gutter: 'vertical',
      css: {
        padding: sizing.contentEndsOnly,
      },
    },
    {
      size: 'x-small',
      gutter: 'horizontal',
      css: {
        padding: sizing.contentSidesOnly,
      },
    },
    {
      size: 'x-small',
      gutter: 'top',
      css: {
        paddingTop: sizing.contentEnds,
      },
    },
    {
      size: 'x-small',
      gutter: 'right',
      css: {
        paddingRight: sizing.contentSides,
      },
    },
    {
      size: 'x-small',
      gutter: 'bottom',
      css: {
        paddingBottom: sizing.contentEnds,
      },
    },
    {
      size: 'x-small',
      gutter: 'left',
      css: {
        paddingLeft: sizing.contentSides,
      },
    },
    {
      size: 'medium',
      gutter: 'all',
      css: {
        padding: sizing.squish,
      },
    },
    {
      size: 'medium',
      gutter: 'vertical',
      css: {
        padding: sizing.endsOnly,
      },
    },
    {
      size: 'medium',
      gutter: 'horizontal',
      css: {
        padding: sizing.sidesOnly,
      },
    },
    {
      size: 'medium',
      gutter: 'top',
      css: {
        paddingTop: sizing.ends,
      },
    },
    {
      size: 'medium',
      gutter: 'right',
      css: {
        paddingRight: sizing.sides,
      },
    },
    {
      size: 'medium',
      gutter: 'bottom',
      css: {
        paddingBottom: sizing.ends,
      },
    },
    {
      size: 'medium',
      gutter: 'left',
      css: {
        paddingLeft: sizing.sides,
      },
    },
  ],
});

const ShellContentScroll = styled(VerticalScrollShadow, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  height: '100%',

  variants: {
    contentMode: {
      fit: {
        height: 'fit-content',
      },
      stretch: {
        height: '100%',
      },
    },
    widthMode: {
      centered: {
        alignItems: 'center',
      },
      full: {},
    },
  },
});

const ShellContentStatic = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  height: 'min-content',

  variants: {
    widthMode: {
      centered: {
        alignSelf: 'center',
      },
      full: {},
    },
  },
});

const ShellContentPosition = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  overflow: 'hidden',

  variants: {
    contentMode: {
      fit: {
        height: 'fit-content',
      },
      stretch: {
        height: '100%',
      },
    },
    variant: {
      scroll: {},
      static: {
        heightAll: 'fit-content',
      },
    },
  },
});

const ShellContentDivider = styled(Divider, {
  padding: `0 $${sizing.primary}`,
});

const ShellContentContainer = styled('div', {
  gridArea: 'shell-content',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  overflow: 'hidden',

  variants: {
    background: {
      checkered: {
        background: backgrounds.checkeredLight,
        backgroundColor: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: backgrounds.checkeredDark,
          backgroundColor: colors.bgApplicationDark,
        },
      },
      dotted: {
        background: backgrounds.dottedLight,
        backgroundColor: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: backgrounds.dottedDark,
          backgroundColor: colors.bgApplicationDark,
        },
      },
      default: {},
    },
  },
});

export type ShellContentProps = {
  /**
   * Set whether the background should be checkered or not.
   */
  background?: 'default' | 'checkered' | 'dotted';
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set the content mode for the content.
   */
  contentMode?: ShellPropContentMode;
  /**
   * Provide controls for the content.
   */
  controls?: React.ReactNode;
  /**
   * Provide glance for the content.
   */
  glance?: React.ReactNode;
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: GutterProp;
  /**
   * Set the size of the shell.
   */
  size?: ShellPropSize;
  /**
   * Set the spacing between the children.
   */
  spacing?: SpacingScale;
  /**
   * Ref to the scroll container.
   */
  scrollRef?: RefObject<HTMLDivElement | null>;
} & ComponentProps<typeof ShellContentContainer>;

export function ShellContent({
  children,
  background,
  controls,
  glance,
  gutter = 'all',
  size,
  spacing = 16,
  scrollRef,
  ref,
  contentMode,
  ...remaining
}: ShellContentProps) {
  const shellContentMode = useShellContentMode(contentMode);
  const shellSize = useShellSize(size);
  const shellWidth = useShellWidth();
  const shellMaxWidth = useShellMaxWidth();
  const shellMinWidth = useShellMinWidth();
  const shellWidthMode = useShellWidthMode();
  const innerScrollRef = useRef<HTMLDivElement>(null);
  const { getBoxShadow, onScrollHandler, handleTargetChange } = useVerticalShadowOnScroll('both');

  const handleResize = useCallback(() => {
    if (innerScrollRef.current) {
      handleTargetChange(innerScrollRef.current);
    }
    if (scrollRef?.current) {
      handleTargetChange(scrollRef.current);
    }
  }, [handleTargetChange, scrollRef]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const scrollProps = {
    ref: scrollRef ?? innerScrollRef,
    onScroll: onScrollHandler,
    ...getBoxShadow,
  };

  return (
    <ShellContentContainer ref={ref} background={background} {...remaining}>
      {controls || glance ? (
        <ShellContentPosition variant="static">
          <ShellContentStatic
            widthMode={shellWidthMode}
            css={merge(
              shellWidth ? widthCSS(shellWidth) : undefined,
              shellMaxWidth ? maxWidthCSS(shellMaxWidth) : undefined,
              shellMinWidth ? minWidthCSS(shellMinWidth) : undefined,
            )}
          >
            {controls && (
              <>
                <ShellContentDivider />
                <ShellContentControls>{controls}</ShellContentControls>
              </>
            )}
            {glance && (
              <>
                <ShellContentDivider />
                <ShellContentGlance>{glance}</ShellContentGlance>
              </>
            )}
          </ShellContentStatic>
        </ShellContentPosition>
      ) : null}
      <ShellContentPosition contentMode={shellContentMode}>
        <ShellContentScroll
          contentMode={shellContentMode}
          widthMode={shellWidthMode}
          {...scrollProps}
        >
          <ShellContentSpacing
            size={shellSize}
            gutter={gutter}
            contentMode={shellContentMode}
            css={merge(
              spacingCSS(spacing),
              shellWidth ? widthCSS(shellWidth) : undefined,
              shellMaxWidth ? maxWidthCSS(shellMaxWidth) : undefined,
              shellMinWidth ? minWidthCSS(shellMinWidth) : undefined,
            )}
          >
            {children}
          </ShellContentSpacing>
        </ShellContentScroll>
      </ShellContentPosition>
    </ShellContentContainer>
  );
}

const ShellFooterEnds = styled(ControlGroup, {
  width: '100%',
});

const ShellFooterStart = styled(ShellFooterEnds);

const ShellFooterEnd = styled(ShellFooterEnds, {
  justifyContent: 'flex-end',
});

export const ShellFooterWidth = styled('div', {
  position: 'relative',
  display: 'flex',
  flexShrink: 0,
  gap: '$12',
  width: '100%',
  overflow: 'auto',
  background: colors.bgApplicationLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
  },

  '@mobile': {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '@notMobile': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '&:before': {
    content: '',
    display: 'block',
    position: 'absolute',
    top: '-0.5px',
    right: sizing.sides,
    left: sizing.sides,
    height: '$1',
    background: colors.strokeApplicationLight,
    borderRadius: '$2',

    [darkThemeSelector]: {
      background: colors.strokeApplicationDark,
    },
  },

  variants: {
    size: {
      'x-small': {
        padding: sizing.contentSidesOnly,

        '@notDesktop': {
          minHeight: '$40',
        },

        '@desktop': {
          minHeight: '$32',
        },
      },
      medium: {
        padding: sizing.sidesOnly,

        '@notDesktop': {
          minHeight: '$52',
        },

        '@desktop': {
          minHeight: '$44',
        },
      },
    },
  },
});

const ShellFooterContainer = styled('div', {
  gridArea: 'shell-footer',
  position: 'relative',
  display: 'flex',

  '@mobile': {
    flexDirection: 'row',
  },

  '@notMobile': {
    flexDirection: 'column',
  },

  variants: {
    widthMode: {
      centered: {
        alignItems: 'center',
      },
      full: {},
    },
  },
});

export type ShellFooterProps = {
  /**
   * The end content for the footer, usually used for actions.
   */
  end?: React.ReactNode;
  /**
   * Optional ref to use for the footer.
   */
  ref?: RefObject<HTMLDivElement | null>;
  /**
   * The start content for the footer, usually used for additional context and helpers.
   */
  start?: React.ReactNode;
  /**
   * Set the size of the shell.
   */
  size?: ShellPropSize;
};

export function ShellFooter({ end, size, start, ref, ...remaining }: ShellFooterProps) {
  const shellSize = useShellSize(size, 'medium');
  const shellWidth = useShellWidth();
  const shellMaxWidth = useShellMaxWidth();
  const shellMinWidth = useShellMinWidth();
  const shellWidthMode = useShellWidthMode();

  return (
    <ShellFooterContainer ref={ref} widthMode={shellWidthMode} {...remaining}>
      <ShellFooterWidth
        size={shellSize}
        css={merge(
          shellWidth ? widthCSS(shellWidth) : undefined,
          shellMaxWidth ? maxWidthCSS(shellMaxWidth) : undefined,
          shellMinWidth ? minWidthCSS(shellMinWidth) : undefined,
        )}
      >
        {start && (
          <ShellFooterStart relation="separate" size={size === 'x-small' ? 'x-small' : 'medium'}>
            {start}
          </ShellFooterStart>
        )}
        {end && (
          <ShellFooterEnd relation="separate" size={size === 'x-small' ? 'x-small' : 'medium'}>
            {end}
          </ShellFooterEnd>
        )}
      </ShellFooterWidth>
    </ShellFooterContainer>
  );
}

export const ShellContainer = styled('div', {
  display: 'grid',
  gridTemplateAreas: '"shell-toolbar" "shell-header" "shell-content" "shell-footer"',
  width: '100%',
  height: '100%',
  overflow: 'hidden',

  '& form': {
    // This allows for the use of a `<form>` inside of any `Shell` components
    // without affecting the flexbox of the children.
    display: 'contents',
  },

  variants: {
    contentMode: {
      fit: {
        gridTemplateRows:
          'fit-content(100%) fit-content(100%) minmax(0, min-content) fit-content(100%)',
      },
      stretch: {
        gridTemplateRows: 'fit-content(100%) fit-content(100%) 1fr fit-content(100%)',
      },
    },
    widthMode: {
      centered: {},
      full: {},
    },
  },
});

export type ShellProps = {
  children: React.ReactNode;
  contentMode?: ShellPropContentMode;
  layoutMode?: ShellPropLayoutMode;
  size?: ShellPropSize;
  width?: WidthTypes;
  maxWidth?: MaxWidthTypes;
  minWidth?: MinWidthTypes;
  widthMode?: ShellPropWidthMode;
  ref?: RefObject<HTMLDivElement | null>;
};

export function Shell({
  children,
  contentMode = 'stretch',
  layoutMode = 'compact',
  size = 'medium',
  width,
  maxWidth,
  minWidth = 0,
  widthMode = 'full',
  ref,
  ...remaining
}: ShellProps) {
  return (
    <ShellSizeProvider value={size}>
      <ShellLayoutModeProvider value={layoutMode}>
        <ShellContentModeProvider value={contentMode}>
          <ShellWidthModeProvider value={widthMode}>
            <ShellWidthProvider value={width}>
              <ShellMaxWidthProvider value={maxWidth}>
                <ShellMinWidthProvider value={minWidth}>
                  <ShellContainer
                    ref={ref}
                    contentMode={contentMode}
                    widthMode={widthMode}
                    css={merge(
                      widthMode === 'full' ? widthCSS(width) : undefined,
                      widthMode === 'full' ? maxWidthCSS(maxWidth) : undefined,
                      widthMode === 'full' ? minWidthCSS(minWidth) : undefined,
                    )}
                    {...remaining}
                  >
                    {children}
                  </ShellContainer>
                </ShellMinWidthProvider>
              </ShellMaxWidthProvider>
            </ShellWidthProvider>
          </ShellWidthModeProvider>
        </ShellContentModeProvider>
      </ShellLayoutModeProvider>
    </ShellSizeProvider>
  );
}
