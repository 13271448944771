import type { VariantProp } from '../common/colors';
import { colors, darkThemeSelector, fontWeights, styled } from '../stitches.config';

export type TextContextProp = 'body' | 'heading';
export type TextVariantProp = VariantProp;
export type TextWeightProp = 'light' | 'regular' | 'medium' | 'bold' | 'extra-bold';

export const Text = styled('span', {
  variants: {
    align: {
      center: {
        textAlign: 'center',
      },
      justify: {
        textAlign: 'justify',
      },
      left: {
        textAlign: 'left',
      },
      right: {
        textAlign: 'right',
      },
    },
    context: {
      body: {},
      heading: {},
    },
    decoration: {
      none: {
        textDecoration: 'none',
      },
      'line-through': {
        textDecoration: 'line-through',
      },
      underline: {
        textDecoration: 'underline',
      },
    },
    display: {
      block: {
        display: 'block',
      },
      flex: {
        display: 'flex',
      },
      inline: {
        display: 'inline',
      },
      'inline-block': {
        display: 'inline-block',
      },
      'inline-flex': {
        display: 'inline-flex',
      },
    },
    family: {
      brand: {
        fontFamily: '$title',
      },
      monospace: {
        fontFamily: '$mono',
      },
      regular: {
        fontFamily: '$sans',
      },
    },
    fontVariantNumeric: {
      'diagonal-fractions': {
        fontVariantNumeric: 'diagonal-fractions',
      },
      lining: {
        fontVariantNumeric: 'lining-nums',
      },
      oldstyle: {
        fontVariantNumeric: 'oldstyle-nums',
      },
      ordinal: {
        fontVariantNumeric: 'ordinal',
      },
      proportional: {
        fontVariantNumeric: 'proportional-nums',
      },
      'slashed-zero': {
        fontVariantNumeric: 'slashed-zero',
      },
      'stacked-fractions': {
        fontVariantNumeric: 'stacked-fractions',
      },
      regular: {
        fontVariantNumeric: 'normal',
      },
      tabular: {
        fontVariantNumeric: 'tabular-nums',
      },
    },
    italicize: {
      italic: {
        fontStyle: 'italic',
      },
      regular: {
        fontStyle: 'normal',
      },
    },
    lineHeight: {
      12: {
        fontSize: '$12',
      },
      16: {
        fontSize: '$16',
      },
      20: {
        fontSize: '$20',
      },
      24: {
        fontSize: '$24',
      },
      28: {
        fontSize: '$28',
      },
      32: {
        fontSize: '$32',
      },
    },
    size: {
      11: {
        fontSize: '$11',
      },
      12: {
        fontSize: '$12',
      },
      13: {
        fontSize: '$13',
      },
      14: {
        fontSize: '$14',
      },
      16: {
        fontSize: '$16',
      },
      20: {
        fontSize: '$20',
      },
      24: {
        fontSize: '$24',
      },
    },
    transform: {
      capitalize: {
        textTransform: 'capitalize',
      },
      lowercase: {
        textTransform: 'lowercase',
      },
      none: {
        textTransform: 'none',
      },
      uppercase: {
        textTransform: 'uppercase',
      },
    },
    truncate: {
      true: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      false: {},
    },
    variant: {
      alternative: {},
      attention: {},
      brand: {},
      negative: {},
      neutral: {},
      positive: {},
    },
    weight: {
      light: {
        fontWeight: fontWeights.light,
      },
      regular: {
        fontWeight: fontWeights.regular,
      },
      medium: {
        fontWeight: fontWeights.bold,
      },
      bold: {
        fontWeight: fontWeights.bold,
      },
      'extra-bold': {
        fontWeight: fontWeights.extraBold,
      },
    },
    whitespace: {
      normal: {
        whiteSpace: 'normal',
      },
      'no-wrap': {
        whiteSpace: 'nowrap',
      },
      pre: {
        whiteSpace: 'pre',
      },
      'pre-line': {
        whiteSpace: 'pre-line',
      },
      'pre-wrap': {
        whiteSpace: 'pre-wrap',
      },
    },
    wordBreak: {
      'break-all': {
        wordBreak: 'break-all',
      },
      'break-word': {
        wordBreak: 'break-word',
      },
      normal: {
        wordBreak: 'normal',
      },
    },

    internal: {
      true: {
        color: colors.internalBodyLight,
        [darkThemeSelector]: {
          color: colors.internalBodyDark,
        },
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      context: 'body',
      variant: 'alternative',
      css: {
        color: colors.bodyAlternativeLight,

        [darkThemeSelector]: {
          color: colors.bodyAlternativeDark,
        },
      },
    },
    {
      context: 'heading',
      variant: 'alternative',
      css: {
        color: colors.headingAlternativeLight,

        [darkThemeSelector]: {
          color: colors.headingAlternativeDark,
        },
      },
    },
    {
      context: 'body',
      variant: 'attention',
      css: {
        color: colors.bodyAttentionLight,

        [darkThemeSelector]: {
          color: colors.bodyAttentionDark,
        },
      },
    },
    {
      context: 'heading',
      variant: 'attention',
      css: {
        color: colors.headingAttentionLight,

        [darkThemeSelector]: {
          color: colors.headingAttentionDark,
        },
      },
    },
    {
      context: 'body',
      variant: 'brand',
      css: {
        color: colors.bodyBrandLight,

        [darkThemeSelector]: {
          color: colors.bodyBrandDark,
        },
      },
    },
    {
      context: 'heading',
      variant: 'brand',
      css: {
        color: colors.headingBrandLight,

        [darkThemeSelector]: {
          color: colors.headingBrandDark,
        },
      },
    },
    {
      context: 'body',
      variant: 'negative',
      css: {
        color: colors.bodyNegativeLight,

        [darkThemeSelector]: {
          color: colors.bodyNegativeDark,
        },
      },
    },
    {
      context: 'heading',
      variant: 'negative',
      css: {
        color: colors.headingNegativeLight,

        [darkThemeSelector]: {
          color: colors.headingNegativeDark,
        },
      },
    },
    {
      context: 'body',
      variant: 'neutral',
      css: {
        color: colors.bodyNeutralLight,

        [darkThemeSelector]: {
          color: colors.bodyNeutralDark,
        },
      },
    },
    {
      context: 'heading',
      variant: 'neutral',
      css: {
        color: colors.headingNeutralLight,

        [darkThemeSelector]: {
          color: colors.headingNeutralDark,
        },
      },
    },
    {
      context: 'body',
      variant: 'positive',
      css: {
        color: colors.bodyPositiveLight,

        [darkThemeSelector]: {
          color: colors.bodyPositiveDark,
        },
      },
    },
    {
      context: 'heading',
      variant: 'positive',
      css: {
        color: colors.headingPositiveLight,

        [darkThemeSelector]: {
          color: colors.headingPositiveDark,
        },
      },
    },
  ],
});
