import type { ButtonHTMLAttributes } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { VariantProp } from '../../common/colors';
import { Icon } from '../../assets/Icon/Icon';
import { fade, palette } from '../../common/colors';
import { FocusRing } from '../../common/focus_rings';
import { selectors } from '../../common/selectors';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { colors, darkThemeSelector, styled } from '../../stitches.config';

const TerminateButtonIcon = styled(Icon, {
  color: '$$iconColor',

  '@notDesktop': {
    width: '$12',
    height: '$12',
  },

  '@desktop': {
    width: '$10',
    height: '$10',
  },
});

const TerminateButtonContainer = styled('button', FocusRing, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@notDesktop': {
    width: '$28',
    height: '$28',
    borderRadius: '$8',
  },

  '@desktop': {
    width: '$20',
    height: '$20',
    borderRadius: '$6',
  },

  variants: {
    internal: {
      true: {},
      false: {},
    },
    variant: {
      alternative: {
        backgroundColor: fade(palette.bgAlternativeLight, 0.7),
        strokeAll: colors.transparent,

        $$iconColor: colors.iconAlternativeLight,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.bgAlternativeDark, 0.7),
          strokeAll: colors.transparent,

          $$iconColor: colors.iconAlternativeDark,
        },

        [selectors.hover]: {
          backgroundColor: fade(palette.bgAlternativeLight, 0.9),
          strokeAll: colors.strokeAlternativeLight,

          $$iconColor: colors.bodyAlternativeLight,

          [darkThemeSelector]: {
            backgroundColor: fade(palette.bgAlternativeDark, 0.9),
            strokeAll: colors.strokeAlternativeDark,

            $$iconColor: colors.bodyAlternativeDark,
          },
        },

        [selectors.focus]: {
          backgroundColor: colors.bgAlternativeLight,

          [darkThemeSelector]: {
            backgroundColor: colors.bgAlternativeDark,
          },
        },
      },
      attention: {
        backgroundColor: fade(palette.bgAttentionLight, 0.7),
        strokeAll: colors.transparent,

        $$iconColor: colors.iconAttentionLight,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.bgAttentionDark, 0.7),
          strokeAll: colors.transparent,

          $$iconColor: colors.iconAttentionDark,
        },

        [selectors.hover]: {
          backgroundColor: fade(palette.bgAttentionLight, 0.9),
          strokeAll: colors.strokeAttentionLight,

          $$iconColor: colors.bodyAttentionLight,

          [darkThemeSelector]: {
            backgroundColor: fade(palette.bgAttentionDark, 0.9),
            strokeAll: colors.strokeAttentionDark,

            $$iconColor: colors.bodyAttentionDark,
          },
        },

        [selectors.focus]: {
          backgroundColor: colors.bgAttentionLight,

          [darkThemeSelector]: {
            backgroundColor: colors.bgAttentionDark,
          },
        },
      },
      brand: {
        backgroundColor: fade(palette.bgBrandLight, 0.7),
        strokeAll: colors.transparent,

        $$iconColor: colors.iconBrandLight,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.bgBrandDark, 0.7),
          strokeAll: colors.transparent,

          $$iconColor: colors.iconBrandDark,
        },

        [selectors.hover]: {
          backgroundColor: fade(palette.bgBrandLight, 0.9),
          strokeAll: colors.strokeBrandLight,

          $$iconColor: colors.bodyBrandLight,

          [darkThemeSelector]: {
            backgroundColor: fade(palette.bgBrandDark, 0.9),
            strokeAll: colors.strokeBrandDark,

            $$iconColor: colors.bodyBrandDark,
          },
        },

        [selectors.focus]: {
          backgroundColor: colors.bgBrandLight,

          [darkThemeSelector]: {
            backgroundColor: colors.bgBrandDark,
          },
        },
      },
      negative: {
        backgroundColor: fade(palette.bgNegativeLight, 0.7),
        strokeAll: colors.transparent,

        $$iconColor: colors.iconNegativeLight,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.bgNegativeDark, 0.7),
          strokeAll: colors.transparent,

          $$iconColor: colors.iconNegativeDark,
        },

        [selectors.hover]: {
          backgroundColor: fade(palette.bgNegativeLight, 0.9),
          strokeAll: colors.strokeNegativeLight,

          $$iconColor: colors.bodyNegativeLight,

          [darkThemeSelector]: {
            backgroundColor: fade(palette.bgNegativeDark, 0.9),
            strokeAll: colors.strokeNegativeDark,

            $$iconColor: colors.bodyNegativeDark,
          },
        },

        [selectors.focus]: {
          backgroundColor: colors.bgNegativeLight,

          [darkThemeSelector]: {
            backgroundColor: colors.bgNegativeDark,
          },
        },
      },
      neutral: {
        backgroundColor: fade(palette.bgNeutralLight, 0.7),
        strokeAll: colors.transparent,

        $$iconColor: colors.iconNeutralLight,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.bgNeutralDark, 0.7),
          strokeAll: colors.transparent,

          $$iconColor: colors.iconNeutralDark,
        },

        [selectors.hover]: {
          backgroundColor: fade(palette.bgNeutralLight, 0.9),
          strokeAll: colors.strokeNeutralLight,

          $$iconColor: colors.bodyNeutralLight,

          [darkThemeSelector]: {
            backgroundColor: fade(palette.bgNeutralDark, 0.9),
            strokeAll: colors.strokeNeutralDark,

            $$iconColor: colors.bodyNeutralDark,
          },
        },

        [selectors.focus]: {
          backgroundColor: colors.bgNeutralLight,

          [darkThemeSelector]: {
            backgroundColor: colors.bgNeutralDark,
          },
        },
      },
      positive: {
        backgroundColor: fade(palette.bgPositiveLight, 0.7),
        strokeAll: colors.transparent,

        $$iconColor: colors.iconPositiveLight,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.bgPositiveDark, 0.7),
          strokeAll: colors.transparent,

          $$iconColor: colors.iconPositiveDark,
        },

        [selectors.hover]: {
          backgroundColor: fade(palette.bgPositiveLight, 0.9),
          strokeAll: colors.strokePositiveLight,

          $$iconColor: colors.bodyPositiveLight,

          [darkThemeSelector]: {
            backgroundColor: fade(palette.bgPositiveDark, 0.9),
            strokeAll: colors.strokePositiveDark,

            $$iconColor: colors.bodyPositiveDark,
          },
        },

        [selectors.focus]: {
          backgroundColor: colors.bgPositiveLight,

          [darkThemeSelector]: {
            backgroundColor: colors.bgPositiveDark,
          },
        },
      },
    },
  },

  compoundVariants: [
    {
      internal: true,
      css: {
        backgroundColor: fade(palette.internalBgLight, 0.7),
        strokeAll: colors.transparent,

        $$iconColor: colors.internalIconLight,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.internalBgDark, 0.7),
          strokeAll: colors.transparent,

          $$iconColor: colors.internalIconDark,
        },

        [selectors.hover]: {
          backgroundColor: fade(palette.internalBgLight, 0.9),
          strokeAll: colors.internalStrokeLight,

          $$iconColor: colors.internalBodyLight,

          [darkThemeSelector]: {
            backgroundColor: fade(palette.internalBgDark, 0.9),
            strokeAll: colors.internalStrokeDark,

            $$iconColor: colors.internalBodyDark,
          },
        },

        [selectors.focus]: {
          backgroundColor: colors.internalBgLight,

          [darkThemeSelector]: {
            backgroundColor: colors.internalBgDark,
          },
        },
      },
    },
  ],
});

export type TerminateButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * The icon to display in the button.
   */
  icon?: IconName;
  /**
   * Whether the button is internal.
   */
  internal?: boolean;
  /**
   * Required! The label of the action button.
   */
  label: React.ReactNode;
  /**
   * The event handler for the close button.
   */
  onClick?: React.ReactEventHandler;
  /**
   * The variant of the close button.
   */
  variant: VariantProp;
  /**
   * The ref of the close button.
   */
  ref?: React.Ref<HTMLButtonElement | null>;
};

export function TerminateButton({
  icon = 'cross',
  internal = false,
  label,
  onClick,
  variant = 'neutral',
  ref,
  ...remaining
}: TerminateButtonProps) {
  return (
    <Tooltip contents={label}>
      <TerminateButtonContainer
        ref={ref}
        type="button"
        onClick={onClick}
        aria-label={`${label}`}
        internal={internal}
        variant={variant}
        {...remaining}
      >
        <TerminateButtonIcon icon={icon} />
      </TerminateButtonContainer>
    </Tooltip>
  );
}
