import type { VariantProp } from '../../common/colors';
import { colors, darkThemeSelector, styled } from '../../stitches.config';

const DividerFill = styled('div', {
  display: 'flex',
  borderRadius: '99em',

  variants: {
    appearance: {
      thick: {},
      thin: {},
    },
    direction: {
      column: {
        heightAll: '100%',
      },
      row: {
        widthAll: '100%',
      },
    },
    variant: {
      application: {
        backgroundColor: colors.strokeApplicationLight,

        [darkThemeSelector]: {
          backgroundColor: colors.strokeApplicationDark,
        },
      },
      alternative: {
        backgroundColor: colors.strokeAlternativeLight,

        [darkThemeSelector]: {
          backgroundColor: colors.strokeAlternativeDark,
        },
      },
      attention: {
        backgroundColor: colors.strokeAttentionLight,

        [darkThemeSelector]: {
          backgroundColor: colors.strokeAttentionDark,
        },
      },
      brand: {
        backgroundColor: colors.strokeBrandLight,

        [darkThemeSelector]: {
          backgroundColor: colors.strokeBrandDark,
        },
      },
      negative: {
        backgroundColor: colors.strokeNegativeLight,

        [darkThemeSelector]: {
          backgroundColor: colors.strokeNegativeDark,
        },
      },
      neutral: {
        backgroundColor: colors.strokeNeutralLight,

        [darkThemeSelector]: {
          backgroundColor: colors.strokeNeutralDark,
        },
      },
      positive: {
        backgroundColor: colors.strokePositiveLight,

        [darkThemeSelector]: {
          backgroundColor: colors.strokePositiveDark,
        },
      },
      selected: {
        backgroundColor: colors.iconBrandLight,

        [darkThemeSelector]: {
          backgroundColor: colors.iconBrandDark,
        },
      },
    },
  },

  compoundVariants: [
    {
      appearance: 'thick',
      direction: 'column',
      css: { widthAll: '$2' },
    },
    {
      appearance: 'thick',
      direction: 'row',
      css: { heightAll: '$2' },
    },
    {
      appearance: 'thin',
      direction: 'column',
      css: { widthAll: '$1' },
    },
    {
      appearance: 'thin',
      direction: 'row',
      css: { heightAll: '$1' },
    },
  ],
});

const DividerContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'stretch',

  variants: {
    direction: {
      column: {
        widthAll: 0,
      },
      row: {
        heightAll: 0,
      },
    },
  },
});

export type DividerProps = {
  appearance?: 'thick' | 'thin';
  direction?: 'column' | 'row';
  variant?: 'application' | 'selected' | VariantProp;
};

export function Divider({
  appearance = 'thin',
  direction = 'row',
  variant = 'neutral',
  ...remaining
}: DividerProps) {
  return (
    <DividerContainer direction={direction} {...remaining}>
      <DividerFill appearance={appearance} direction={direction} variant={variant} />
    </DividerContainer>
  );
}
