import { createContext, useContext } from 'react';

import type { VariantProp } from './colors';
import { colors, darkThemeSelector } from '../stitches.config';
import { fade, palette } from './colors';
import { selectors } from './selectors';

const VariantContext = createContext<VariantProp | undefined>(undefined);

/**
 * Allows setting a default variant for all descendent components.
 *
 * @example
 *   <VariantProvider value={VariantProp.Negative}>
 *     <Badge>Negative badge</Badge>
 *   </VariantProvider>
 */
export const VariantProvider = VariantContext.Provider;

/**
 * Returns the current variant from context if defined, otherwise returns the default variant.
 */
export const useVariant = (
  controlledValue?: VariantProp,
  defaultValue: VariantProp = 'neutral',
) => {
  const variant = useContext(VariantContext);
  return controlledValue ?? variant ?? defaultValue;
};

// Base styles
export const variantBackgroundColor = '$$variantBackgroundColor';
export const variantStrokeColor = '$$variantStrokeColor';
export const variantHeadingColor = '$$variantHeadingColor';
export const variantBodyColor = '$$variantBodyColor';
export const variantIconColor = '$$variantIconColor';

// Token specific styles
export const variantTokenBgColor = '$$variantTokenBgColor';
export const variantTokenStrokeColor = '$$variantTokenStrokeColor';
export const variantTokenLabelColor = '$$variantTokenLabelColor';
export const variantTokenIconColor = '$$variantTokenIconColor';

export const getVariantStyles = (variant: VariantProp, internal?: boolean) => {
  const capitalizeVariant = variant.charAt(0).toUpperCase() + variant.slice(1);

  const getThemeColors = (theme: 'Light' | 'Dark') => ({
    background: internal
      ? colors[`internalBg${theme}` as keyof typeof colors]
      : colors[`bg${capitalizeVariant}${theme}` as keyof typeof colors],
    stroke: internal
      ? colors[`internalStroke${theme}` as keyof typeof colors]
      : colors[`stroke${capitalizeVariant}${theme}` as keyof typeof colors],
    heading: internal
      ? colors[`internalHeading${theme}` as keyof typeof colors]
      : colors[`heading${capitalizeVariant}${theme}` as keyof typeof colors],
    body: internal
      ? colors[`internalBody${theme}` as keyof typeof colors]
      : colors[`body${capitalizeVariant}${theme}` as keyof typeof colors],
    icon: internal
      ? colors[`internalIcon${theme}` as keyof typeof colors]
      : colors[`icon${capitalizeVariant}${theme}` as keyof typeof colors],
    interactionBody: internal
      ? colors[`internalHeading${theme}` as keyof typeof colors]
      : colors[`heading${capitalizeVariant}${theme}` as keyof typeof colors],
    interactionIcon: internal
      ? colors[`internalBody${theme}` as keyof typeof colors]
      : colors[`body${capitalizeVariant}${theme}` as keyof typeof colors],

    // Token specific styles
    tokenBg: internal
      ? fade(
          palette[`internalTokenBg${theme}` as keyof typeof palette],
          theme === 'Light' ? 0.75 : 1,
        )
      : fade(
          palette[`tokenBg${capitalizeVariant}${theme}` as keyof typeof palette],
          theme === 'Light' ? 0.75 : 1,
        ),
    tokenStroke: internal
      ? colors[`internalTokenStroke${theme}` as keyof typeof colors]
      : colors[`tokenStroke${capitalizeVariant}${theme}` as keyof typeof colors],
    tokenLabel: internal
      ? colors[`internalTokenLabel${theme}` as keyof typeof colors]
      : colors[`tokenLabel${capitalizeVariant}${theme}` as keyof typeof colors],
    tokenIcon: internal
      ? colors[`internalTokenIcon${theme}` as keyof typeof colors]
      : colors[`tokenIcon${capitalizeVariant}${theme}` as keyof typeof colors],
  });

  const lightColors = getThemeColors('Light');
  const darkColors = getThemeColors('Dark');

  return {
    [variantBackgroundColor]: lightColors.background,
    [variantStrokeColor]: lightColors.stroke,
    [variantHeadingColor]: lightColors.heading,
    [variantBodyColor]: lightColors.body,
    [variantIconColor]: lightColors.icon,

    [variantTokenBgColor]: lightColors.tokenBg,
    [variantTokenStrokeColor]: lightColors.tokenStroke,
    [variantTokenLabelColor]: lightColors.tokenLabel,
    [variantTokenIconColor]: lightColors.tokenIcon,

    [darkThemeSelector]: {
      [variantBackgroundColor]: darkColors.background,
      [variantStrokeColor]: darkColors.stroke,
      [variantHeadingColor]: darkColors.heading,
      [variantBodyColor]: darkColors.body,
      [variantIconColor]: darkColors.icon,

      [variantTokenBgColor]: darkColors.tokenBg,
      [variantTokenStrokeColor]: darkColors.tokenStroke,
      [variantTokenLabelColor]: darkColors.tokenLabel,
      [variantTokenIconColor]: darkColors.tokenIcon,
    },

    [selectors.hover]: {
      [variantBodyColor]: lightColors.interactionBody,
      [variantIconColor]: lightColors.interactionIcon,

      [darkThemeSelector]: {
        [variantBodyColor]: darkColors.interactionBody,
        [variantIconColor]: darkColors.interactionIcon,
      },
    },

    [selectors.focus]: {
      [variantBodyColor]: lightColors.interactionBody,
      [variantIconColor]: lightColors.interactionIcon,

      [darkThemeSelector]: {
        [variantBodyColor]: darkColors.interactionBody,
        [variantIconColor]: darkColors.interactionIcon,
      },
    },
  };
};
