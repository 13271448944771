import type { SliderProps as RadixSliderProps } from '@radix-ui/react-slider';
import * as SliderPrimitive from '@radix-ui/react-slider';
import React, { useCallback, useMemo, useState } from 'react';

import { Icon } from '../../assets/Icon/Icon';
import { fade, palette } from '../../common/colors';
import { TextInput } from '../../components/TextInput/TextInput';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { Small } from '../../text/Small';
import { selectors, transitions } from '../shared/styles';

const sliderValues = {
  thumbSize: 14,
};

const SliderMarker = styled(Small, {
  position: 'absolute',
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  width: 0,
  color: colors.controlContentAccentLight,
  fontVariantNumeric: 'tabular-nums',
  fontWeight: '$extraBold',
  whiteSpace: 'nowrap',

  [darkThemeSelector]: {
    color: colors.controlContentAccentDark,
  },

  variants: {
    isMin: {
      true: {
        left: 0,
        justifyContent: 'flex-start',
      },
      false: {},
    },
    isMax: {
      true: {
        right: 0,
        justifyContent: 'flex-end',
        marginLeft: `$${sliderValues.thumbSize / 2}`,
      },
      false: {},
    },
    isSelected: {
      true: {
        color: colors.brand600,

        [darkThemeSelector]: {
          color: colors.brand300,
        },
      },
      false: {},
    },
  },
});

const SliderMarkers = styled('div', {
  position: 'relative',
  width: '100%',
  minHeight: '$16',
});

const SliderThumbIcon = styled(Icon, {
  widthAll: '$8',
  heightAll: '$8',
  color: '$$dotColor',
  transition: transitions.control,

  [darkThemeSelector]: {
    color: '$$dotColor',
  },
});

const SliderThumb = styled(SliderPrimitive.Thumb, {
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: `$${sliderValues.thumbSize}`,
  height: `$${sliderValues.thumbSize}`,
  background: colors.bgApplicationLight,
  boxShadow: shadows.controlRaisedInitialLight,
  borderRadius: '$4',
  transition: transitions.control,
  translateY: '-50%',
  outline: 'none',
  userSelect: 'none',
  cursor: 'grab',

  $$dotColor: colors.gray200,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.controlRaisedInitialDark,

    $$dotColor: colors.gray600,
  },

  [selectors.hover]: {
    boxShadow: shadows.controlRaisedHoveredLight,

    $$dotColor: colors.gray300,

    [darkThemeSelector]: {
      boxShadow: shadows.controlRaisedHoveredDark,

      $$dotColor: colors.gray500,
    },
  },

  [selectors.focus]: {
    boxShadow: shadows.controlRaisedFocusedLight,

    [darkThemeSelector]: {
      boxShadow: shadows.controlRaisedFocusedDark,
    },
  },

  '&[data-disabled]': {
    boxShadow: shadows.controlRaisedDisabledLight,

    $$dotColor: colors.gray200,

    [darkThemeSelector]: {
      boxShadow: shadows.controlRaisedDisabledDark,

      $$dotColor: colors.gray600,
    },
  },
});

const SliderRange = styled(SliderPrimitive.Range, {
  position: 'absolute',
  top: 0,
  bottom: 0,
  zIndex: 0,
  backgroundColor: colors.brand600,
  borderRadius: '99em',

  '&[data-disabled]': {
    backgroundColor: colors.brand300,

    [darkThemeSelector]: {
      backgroundColor: colors.brand800,
    },
  },
});

const SliderTrack = styled(SliderPrimitive.Track, {
  position: 'relative',
  zIndex: 0,
  width: '100%',
  height: '$2',
  backgroundColor: colors.tokenBgNeutralLight,
  borderRadius: '$8',

  [darkThemeSelector]: {
    backgroundColor: colors.tokenBgNeutralDark,
  },
});

const SliderFields = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$8',
});

const SliderArea = styled(SliderPrimitive.Root, {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  width: '100%',
});

const SliderSpectrumMarker = styled(Small, {
  display: 'flex',
  fontWeight: '$extraBold',
  color: colors.controlContentAccentLight,

  [darkThemeSelector]: {
    color: colors.controlContentAccentDark,
  },

  variants: {
    isSelected: {
      true: {
        color: colors.brand600,

        [darkThemeSelector]: {
          color: colors.brand300,
        },
      },
      false: {},
    },
  },
});

const SliderSpectrum = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
});

const SliderAreaContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  padding: '$12',
  borderRadius: '$8',
  background: colors.bgApplicationLight,
  strokeAll: fade(palette.controlStrokeBaseLight, 0.12),

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    strokeAll: fade(palette.controlStrokeBaseDark, 0.44),
  },

  variants: {
    disabled: {
      true: {
        background: colors.bgNeutralLight,
        strokeAll: fade(palette.controlStrokeBaseLight, 0.3),
        opacity: 0.5,
        pointerEvents: 'none',

        [darkThemeSelector]: {
          background: colors.bgNeutralDark,
          strokeAll: fade(palette.controlStrokeBaseDark, 0.36),
        },
      },
    },
  },
});

const SliderContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  width: '100%',
  userSelect: 'none',
  touchAction: 'none',
});

export type SliderProps = RadixSliderProps & {
  /**
   * Whether to show the fields for the slider values.
   */
  showFields?: boolean;
  /**
   * The markers you want to show along the slider.
   */
  markers?: {
    /**
     * The marker you want to show along the slider at the min value.
     */
    min?: React.ReactNode;
    /**
     * The markers you want to show along the slider between the min and max.
     */
    mid?: number[] | React.ReactNode[];
    /**
     * The marker you want to show along the slider at the max value.
     */
    max?: React.ReactNode;
  };
  /**
   * Whether to show the markers on the slider.
   */
  showMarkers?: boolean;
  /**
   * Whether to show the tooltips for the slider values.
   */
  showTooltips?: boolean;
  /**
   * The spectrum of the slider, used to indicate the context of the min and max values (e.g. 'Highest', 'Lowest', 'Fastest', 'Slowest', etc.).
   */
  spectrum?: {
    min: React.ReactNode;
    max: React.ReactNode;
  };
  /**
   * The unit of the slider values (e.g. '%', 'Kbps', etc.).
   */
  unit?: React.ReactNode;
};

export const Slider = React.forwardRef<HTMLSpanElement, SliderProps>((props, forwardedRef) => {
  const {
    disabled,
    onValueChange,
    markers,
    min = 0,
    max = 100,
    showFields,
    showMarkers,
    showTooltips = true,
    spectrum,
    unit,
  } = props;

  const values = useMemo(
    () => props.value ?? props.defaultValue ?? [],
    [props.value, props.defaultValue],
  );

  const handleValueChange = useCallback(
    (index: number) => (value: string) => {
      if (!onValueChange) return;
      const newValues = values.slice();
      const num = Number(value);
      if (Number.isNaN(num)) return;
      newValues[index] = num;
      onValueChange(newValues);
    },
    [onValueChange, values],
  );

  const [isHovered, setHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const isMin = useMemo(() => values[0] === min, [values, min]);
  const isMax = useMemo(() => values[values.length - 1] === max, [values, max]);

  const midMarkerMath = (index: number) =>
    ((Number(index) + 1) * 100) / ((markers?.mid?.length ?? 0) + 1);

  return (
    <SliderContainer>
      <SliderAreaContainer
        disabled={props.disabled}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
      >
        {showMarkers && (
          <SliderMarkers>
            <SliderMarker isMin isSelected={isMin}>
              {markers?.min ?? `${min}${unit ?? ''}`}
            </SliderMarker>
            {markers?.mid?.map((marker, index) => (
              <SliderMarker
                key={`markers-mid-${marker}`}
                isSelected={values.includes(index + 2)}
                css={{
                  left: `calc(${midMarkerMath(index)}%)`,
                }}
              >
                {marker}
                {unit}
              </SliderMarker>
            ))}
            <SliderMarker isMax isSelected={isMax}>
              {markers?.max ?? `${max}${unit ?? ''}`}
            </SliderMarker>
          </SliderMarkers>
        )}
        <SliderArea
          disabled={props.disabled}
          orientation="horizontal"
          {...props}
          ref={forwardedRef}
        >
          <SliderTrack>
            <SliderRange />
          </SliderTrack>
          {values.map((value, i) => {
            const thumb = (
              <SliderThumb
                tabIndex={disabled ? undefined : 0}
                data-disabled={disabled}
                // eslint-disable-next-line react/no-array-index-key
                key={`thumb-${i}`}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onBlur={() => {
                  setIsFocused(false);
                }}
              >
                <SliderThumbIcon icon="slide-horizontal" />
              </SliderThumb>
            );
            return showTooltips ? (
              <Tooltip
                contents={
                  <>
                    {value}
                    {unit}
                  </>
                }
                isOpen={isHovered || isFocused}
              >
                {thumb}
              </Tooltip>
            ) : (
              thumb
            );
          })}
        </SliderArea>
        {spectrum && (
          <SliderSpectrum>
            <SliderSpectrumMarker isSelected={isMin}>{spectrum.min}</SliderSpectrumMarker>
            <SliderSpectrumMarker isSelected={isMax}>{spectrum.max}</SliderSpectrumMarker>
          </SliderSpectrum>
        )}
      </SliderAreaContainer>
      {showFields && (
        <SliderFields>
          {values.map((value, i) => (
            <TextInput
              disabled={disabled}
              // eslint-disable-next-line react/no-array-index-key
              key={`input-${i}`}
              type="number"
              width="100%"
              value={`${value}`}
              onChange={handleValueChange(i)}
              suffix={unit}
            />
          ))}
        </SliderFields>
      )}
    </SliderContainer>
  );
});
