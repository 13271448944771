import type { ReactNode } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { VariantProp } from '../../common/colors';
import type { PolymorphicComponentProps } from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { LoadingIcon } from '../../assets/LoadingIcon/LoadingIcon';
import { zIndexes } from '../../common/z_indexes';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import {
  colors,
  darkThemeSelector,
  fontWeights,
  keyframes,
  shadows,
  styled,
} from '../../stitches.config';
import { Body } from '../../text/Body';
import { Small } from '../../text/Small';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import { isDefined } from '../../utilities/isDefined';
import { VStack } from '../../utilities/Stack/VStack';

const toastIconRunningSpin = keyframes({
  '50%': { transform: 'rotate(180deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

const ToastIconRunning = styled(Icon, {
  width: '$14',
  height: '$14',
  color: '$$iconColor',
  animation: `${toastIconRunningSpin} 2s linear infinite`,
});

const ToastIconLoading = styled(LoadingIcon, {
  width: '$14',
  height: '$14',
  color: '$$iconColor',
});

const ToastIconGlyph = styled(Icon, {
  width: '$14',
  height: '$14',
  color: '$$iconColor',
});

const ToastHeading = styled(Body, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$4',
  color: '$$headingColor',
  fontWeight: fontWeights.bold,
});

const ToastDescription = styled(Small, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$4',
  color: '$$descriptionColor',
});

const ToastContainer = styled('div', {
  position: 'relative',
  zIndex: zIndexes.alert,
  display: 'flex',
  flexDirection: 'row',
  width: 'max-content',
  maxWidth: '$720',
  padding: '$8 $12',
  backgroundColor: '$$backgroundColor',
  boxShadow: shadows.overlayLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    boxShadow: shadows.overlayDark,
  },

  '&:before': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '$10',
    strokeAll: '$$strokeColor',
  },

  variants: {
    variant: {
      alternative: {
        $$backgroundColor: colors.bgAlternativeLight,
        $$strokeColor: colors.strokeAlternativeLight,
        $$iconColor: colors.iconAlternativeLight,
        $$headingColor: colors.headingAlternativeLight,
        $$descriptionColor: colors.bodyAlternativeLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgAlternativeDark,
          $$strokeColor: colors.strokeAlternativeDark,
          $$iconColor: colors.iconAlternativeDark,
          $$headingColor: colors.headingAlternativeDark,
          $$descriptionColor: colors.bodyAlternativeDark,
        },
      },
      attention: {
        $$backgroundColor: colors.bgAttentionLight,
        $$strokeColor: colors.strokeAttentionLight,
        $$iconColor: colors.iconAttentionLight,
        $$headingColor: colors.headingAttentionLight,
        $$descriptionColor: colors.bodyAttentionLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgAttentionDark,
          $$strokeColor: colors.strokeAttentionDark,
          $$iconColor: colors.iconAttentionDark,
          $$headingColor: colors.headingAttentionDark,
          $$descriptionColor: colors.bodyAttentionDark,
        },
      },
      brand: {
        $$backgroundColor: colors.bgBrandLight,
        $$strokeColor: colors.strokeBrandLight,
        $$iconColor: colors.iconBrandLight,
        $$headingColor: colors.headingBrandLight,
        $$descriptionColor: colors.bodyBrandLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgBrandDark,
          $$strokeColor: colors.strokeBrandDark,
          $$iconColor: colors.iconBrandDark,
          $$headingColor: colors.headingBrandDark,
          $$descriptionColor: colors.bodyBrandDark,
        },
      },
      negative: {
        $$backgroundColor: colors.bgNegativeLight,
        $$strokeColor: colors.strokeNegativeLight,
        $$iconColor: colors.iconNegativeLight,
        $$headingColor: colors.headingNegativeLight,
        $$descriptionColor: colors.bodyNegativeLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgNegativeDark,
          $$strokeColor: colors.strokeNegativeDark,
          $$iconColor: colors.iconNegativeDark,
          $$headingColor: colors.headingNegativeDark,
          $$descriptionColor: colors.bodyNegativeDark,
        },
      },
      neutral: {
        $$backgroundColor: colors.bgNeutralLight,
        $$strokeColor: colors.strokeNeutralLight,
        $$iconColor: colors.iconNeutralLight,
        $$headingColor: colors.headingNeutralLight,
        $$descriptionColor: colors.bodyNeutralLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgNeutralDark,
          $$strokeColor: colors.strokeNeutralDark,
          $$iconColor: colors.iconNeutralDark,
          $$headingColor: colors.headingNeutralDark,
          $$descriptionColor: colors.bodyNeutralDark,
        },
      },
      positive: {
        $$backgroundColor: colors.bgPositiveLight,
        $$strokeColor: colors.strokePositiveLight,
        $$iconColor: colors.iconPositiveLight,
        $$headingColor: colors.headingPositiveLight,
        $$descriptionColor: colors.bodyPositiveLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgPositiveDark,
          $$strokeColor: colors.strokePositiveDark,
          $$iconColor: colors.iconPositiveDark,
          $$headingColor: colors.headingPositiveDark,
          $$descriptionColor: colors.bodyPositiveDark,
        },
      },
    },
  },
});

export interface ToastProps {
  /**
   * The actions to display in the toast.
   */
  actions?: ReactNode;
  /**
   * The description to display in the toast.
   */
  description?: ReactNode;
  /**
   * The heading to display in the toast.
   */
  heading: ReactNode;
  /**
   * The icon to display in the toast.
   */
  icon?: IconName;
  /**
   * The state of the toast's related event.
   */
  state?: 'loading' | 'running';
  /**
   * The variant of the toast.
   */
  variant?: VariantProp;
}

function ToastIcon({
  icon,
  state,
}: Omit<ToastProps, 'actions' | 'heading' | 'description' | 'variant'>) {
  if (state === 'loading') {
    return <ToastIconLoading />;
  }
  if (state === 'running') {
    return <ToastIconRunning icon="arrows-rotate" />;
  }
  if (icon) {
    return <ToastIconGlyph icon={icon} />;
  }

  return null;
}

export function Toast<Tag extends React.ElementType>({
  actions,
  description,
  heading,
  icon,
  state,
  ref,
  variant = 'neutral',
  ...props
}: PolymorphicComponentProps<Tag, ToastProps>) {
  return (
    <ToastContainer {...props} ref={ref} variant={variant}>
      <AlignStack
        direction="row"
        gap={{
          start: 6,
          end: 8,
        }}
        preset="body"
        start={(isDefined(icon) || isDefined(state)) && <ToastIcon icon={icon} state={state} />}
        end={
          isDefined(actions) && (
            <ControlGroup relation="separate" size="small">
              {actions}
            </ControlGroup>
          )
        }
      >
        <VStack>
          {heading && <ToastHeading>{heading}</ToastHeading>}
          {description && <ToastDescription>{description}</ToastDescription>}
        </VStack>
      </AlignStack>
    </ToastContainer>
  );
}
